import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import { VERIFY_ACCOUNT } from "../../apis/handleAccount";
import { GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import CheckIcon from "@mui/icons-material/Check";
import {
  columns,
  notification_verify,
  notification_unverify,
  notification_ban,
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
} from "./components/components";
import { Store } from "react-notifications-component";
import { CustomToolbar } from "../../components/function";
import { GET_ALL_CUSTOMER } from "../../apis/handleCustomer";
import { SearchComponent } from "../../components/components";
const Customer = ({ data }) => {
  const [stateAccount, setStateAccount] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);

  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateName, setStateName] = useState("");
  const [stateSort, setStateSort] = useState("asc");
  const [stateDate, setStateDate] = useState("latest");
  const [stateStatus, setStateStatus] = useState("");
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DATA_GET_ALL_CUSTOMER = useQuery({
    queryKey: [
      "GET_ALL_CUSTOMER",
      statePage,
      stateStatus,
      stateDate,
      stateName,
    ],
    queryFn: async () => {
      const res = await GET_ALL_CUSTOMER({
        page: statePage + 1,
        limit: stateLimit,
        name: stateName,
        date: stateDate,
        sort: stateSort,
        status: stateStatus,
      });
      if (res?.data?.data) {
        let newArray = res?.data?.data?.customers.map((item, index) => {
          return {
            ...item,
            id: item?._id,
          };
        });
        // setStatePage(res?.data?.data?.page);

        setStateListUpgrade(newArray);
        setRowCount(parseInt(res?.data?.data?.total_customers));
      }

      return res;
    },
  });

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return VERIFY_ACCOUNT(body);
    },
  });

  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_verify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_verify);

                  setIsloading({
                    ...Isloading,
                    loading_verify: false,
                  });
                  setSelectedRow([]);
                  setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_verify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  const handleBANNED = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_ban: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_BANNED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_ban);

                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_ban: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_ban: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleUNVERIFY = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_unverify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_unverify);

                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_unverify: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_unverify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  return (
    <Box m="20px">
      <Header
        title="Quản lý khách hàng"
        subtitle={"Tất cả dữ liệu khác hàng"}
      />
      <Box gap={2} className="row">
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          gap={3}
          bgcolor={"#607CFB"}
          className="col-md-3"
          p={3}
        >
          <LinkOffIcon> </LinkOffIcon>
          <Typography fontSize={"1.2rem"}>
            Chưa liên kết :{" "}
            {DATA_GET_ALL_CUSTOMER?.data?.data?.data?.customer_not_linked}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          gap={3}
          bgcolor={"orangered"}
          className="col-md-3"
          p={3}
        >
          <DatasetLinkedIcon></DatasetLinkedIcon>
          <Typography fontSize={"1.2rem"}>
            Đã tham gia MALL :{" "}
            {DATA_GET_ALL_CUSTOMER?.data?.data?.data?.customer_linked}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          gap={3}
          bgcolor={"blueviolet"}
          className="col-md-3"
          p={3}
        >
          <CheckIcon></CheckIcon>
          <Typography fontSize={"1.2rem"}>
            Đã liên kết :{" "}
            {DATA_GET_ALL_CUSTOMER?.data?.data?.data?.customer_active}
          </Typography>
        </Box>
      </Box>
      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateName}
          setValue={setStateName}
          placeholder={"Tìm kiếm tên khách hàng...."}
        ></SearchComponent>
      </Box>
      <Stack
        direction="row"
        width={"50%"}
        // divider={<Divider orientation="vertical" flexItem />}
        justifyContent={"space-between"}
      >
        <Box
          p={2}
          flex={1}
          textAlign={"center"}
          borderRight={"solid 1px #f7f7f7"}
          paddingLeft={"0px"}
        >
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Chọn lọc
          </Typography>
          <SelectOldNewOrder
            setStatePage={setStatePage}
            setStateSortDate={setStateDate}
            stateSortDate={stateDate}
          ></SelectOldNewOrder>
        </Box>
        <Box p={2} flex={1} textAlign={"center"}>
          {" "}
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Tình Trạng
          </Typography>
          <Typography fontWeight={600}>
            <UnstyledSelectIntroduction
              stateStatus={stateStatus}
              setStatePage={setStatePage}
              setStateStatus={setStateStatus}
              // handleRefreshData={handleRefreshData}
            ></UnstyledSelectIntroduction>
          </Typography>
        </Box>
      </Stack>
      <Box
        m="40px 0 0 0"
        height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.4rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={stateListUpgrade || []}
          columns={columns}
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Customer;
