import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import {
  GET_ALL_ACCOUNT_UPGRADE,
  ACCEPT_UPGRADE_ACCOUNT,
} from "../../apis/handleAccount";
import { GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LoginIcon from "@mui/icons-material/Login";
import { columns, notification_verify } from "./components/components";
import { Store } from "react-notifications-component";
import { CustomToolbar } from "../../components/function";
import SearchStatisticUpgrade from "./SearchStatisticUpgrade";
import { CONFIRM_TRANSACTION } from "../../apis/handleTransaction";
import {
  UnstyledSelectIntroduction,
  SelectOldNewOrder,
} from "./components/components";
import { SearchComponent } from "../../components/components";
const AccountManage = ({ data }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [stateSort, setStateSort] = useState("asc");
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateCodeUpgradeSearch, setStateCodeUpgradeSearch] = useState("");
  const [stateStatus, setStateStatus] = useState("");
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(10);
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DATA_GET_ALL_ACCOUNT = useQuery({
    queryKey: [
      "GET_ALL_ACCOUNT_UPGRADE",
      statePage,
      stateStatus,
      stateSortDate,
      stateCodeUpgradeSearch,
    ],
    queryFn: async () => {
      const res = await GET_ALL_ACCOUNT_UPGRADE({
        page: statePage + 1,
        limit: stateLimit,
        sort: stateSort,
        status: stateStatus,
        code_upgrade: stateCodeUpgradeSearch,
        date: stateSortDate,
      });
      if (res?.data?.data) {
        let newArray = res?.data?.data?.upgrade.map((item, index) => {
          return {
            ...item,
            id: item._id,
          };
        });
        setStateListUpgrade(newArray);
        setRowCount(parseInt(res?.data?.data?.total_upgrade));
      }

      return res;
    },
  });

  const HANDLE_CONFIRM_TRANSACTION = useMutation({
    mutationFn: (body) => {
      return CONFIRM_TRANSACTION(body);
    },
  });

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const hasAcceptedOrCancelled = newSelectionModel.some((selectedId) => {
      const selectedRow = stateListUpgrade.find((row) => row.id === selectedId);
      return (
        parseInt(selectedRow.status) !==
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_PENDING) ||
        !selectedRow?.is_sent_payment
      );
    });

    if (hasAcceptedOrCancelled) {
      alert("Đơn này phải là đang chờ và đã thanh toán !");
      return;
    }

    const selectedRows = newSelectionModel.map((selectedId) =>
      stateListUpgrade
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return ACCEPT_UPGRADE_ACCOUNT(body);
    },
  });

  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_verify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                upgrade_id: body._id,
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_verify);
                  DATA_GET_ALL_ACCOUNT.refetch();
                  setIsloading({
                    ...Isloading,
                    loading_verify: false,
                  });
                  HANDLE_CONFIRM_TRANSACTION.mutate(
                    {
                      code: body?.code_upgrade,
                    },
                    {
                      onSuccess: (data) => {
                        console.log("data " + data);
                      },
                      onError: (err) => {
                        console.log("err transaction" + err);
                      },
                    }
                  );
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_verify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  return (
    <Box m="20px">
      <Header title="Quản lý các gói nâng cấp" />
      <ButtonGroup variant="contained" aria-label="Basic button group">
        {Isloading.loading_verify ? (
          <Button color="success">
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={handleAccess}
            disabled={selectionModel.length !== 1}
            color="success"
          >
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              Xác nhận
            </Typography>
          </Button>
        )}
      </ButtonGroup>
      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateCodeUpgradeSearch}
          setValue={setStateCodeUpgradeSearch}
          placeholder={"Tìm kiếm Mã đơn nâng cấp...."}
        ></SearchComponent>
      </Box>
      <Stack
        direction="row"
        width={"50%"}
        // divider={<Divider orientation="vertical" flexItem />}
        justifyContent={"space-between"}
      >
        <Box
          p={2}
          flex={1}
          textAlign={"center"}
          borderRight={"solid 1px #f7f7f7"}
          paddingLeft={"0px"}
        >
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Chọn lọc
          </Typography>
          <SelectOldNewOrder
            setStatePage={setStatePage}
            setStateSortDate={setStateSortDate}
            stateSortDate={stateSortDate}
          ></SelectOldNewOrder>
        </Box>
        <Box p={2} flex={1} textAlign={"center"}>
          {" "}
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Tình Trạng
          </Typography>
          <Typography fontWeight={600}>
            <UnstyledSelectIntroduction
              stateStatus={stateStatus}
              setStatePage={setStatePage}
              setStateStatus={setStateStatus}
              // handleRefreshData={handleRefreshData}
            ></UnstyledSelectIntroduction>
          </Typography>
        </Box>
      </Stack>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={stateListUpgrade || []}
          columns={columns}
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          selectionModel={selectionModel}
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          onSelectionModelChange={handleSelectionModelChange}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>

      <Box mt={5}>
        <Divider>Thống kê</Divider>
        <SearchStatisticUpgrade></SearchStatisticUpgrade>
      </Box>
    </Box>
  );
};

export default AccountManage;
