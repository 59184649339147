import api from "./apis";
import NProgress from "nprogress";
export const CREATE_CATEGORY = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(`/admin/categories`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const UPDATE_CATEGORY = async (req) => {
  try {
    const id = req.get("id");

    NProgress.start();
    const respod = await api.put(`/admin/categories/${id}`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
