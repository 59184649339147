import api from "./apis";
import NProgress from "nprogress";

export const GET_ALL_PURCHASE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/purchases/get-all`, {
      params: {
        page: req.page,
        limit: req.limit,

        status: req.status,
        code_purchase: req.code_purchase,
        date: req.date,
        sort: req.sort,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_PURCHASE_SEARCH = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/purchases/get-all`, {
      params: {
        page: req.page,
        limit: req.limit,

        code_purchase: req.code_purchase,
        date: req.date,
        sort: req.sort,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Update_Purchase_Status = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/purchases/change-status`,
      {
        purchase_id: req.id,
        status: req.status,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Get_Purchase_By_PDP = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-by-pdp`,
      {
        user_id: req.user_id,
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Statistic_PDP_Year_Month_day = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-affiliate-by-pdp`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
        limit: req.limit,
        page: req.page,
        name: req.name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Handle_Statistic_SELLER_Year_Month_day = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-affiliate-and-invite`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
        limit: req.limit,
        page: req.page,
        name: req.name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Search_Get_Purchase_Admin = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Handle_Statistic_Get_Purchase_Admin_By_month_year = async (
  req
) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-by-month`,
      {
        month: req.month,
        year: req.year,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Statistic_Get_Purchase_SAN_By_month_year = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-admin-by-month`,
      {
        user_id: process.env.REACT_APP_ID_SAN,
        month: req.month,
        year: req.year,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Search_Get_Purchase_SAN = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-by-admin`,
      {
        user_id: process.env.REACT_APP_ID_SAN,
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Get_Purchase_By_SELLER = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-by-seller`,
      {
        user_id: req.user_id,
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Get_Statistic_Invite_By_SELLER = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/admin/statistics/statistic-revenues-invite-by-seller`,
      {
        user_id: req.user_id,
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
