import { Box, Button, ButtonGroup, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import {
  GET_ALL_USER,
  UPDATE_USER_ACCESS,
  UPDATE_USER_UNVERIFY,
  UPDATE_USER_BANNED,
} from "../../apis/handleAgency";
import { GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import LoginIcon from "@mui/icons-material/Login";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
const Agency = ({ data }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },

    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },

    {
      field: "verify",
      headerName: "verify",
      flex: 1,
      renderCell: ({ row: { verify } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={
              verify == 0
                ? colors.grey[600]
                : verify == 1
                ? colors.greenAccent[700]
                : colors.redAccent[700]
            }
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {verify == 0 ? "UNVERIFY" : verify == 1 ? "VERIFY" : "BANNED"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      renderCell: ({ row: { roles } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              roles === 0
                ? colors.greenAccent[600]
                : roles === 1
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {roles === 0 && <AdminPanelSettingsOutlinedIcon />}
            {roles === 1 && <SecurityOutlinedIcon />}
            {roles === 2 && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {roles === 0 ? "ADMIN" : roles === 1 ? "PDP" : "USER"}
            </Typography>
          </Box>
        );
      },
    },
  ];
  const ACCEPT_ACCESS = useMutation({
    mutationFn: (body) => {
      UPDATE_USER_ACCESS(body);
    },
  });
  const UNVERIFY = useMutation({
    mutationFn: (body) => {
      UPDATE_USER_UNVERIFY(body);
    },
  });
  const BANNED = useMutation({
    mutationFn: (body) => {
      UPDATE_USER_BANNED(body);
    },
  });
  const DATA_ALL_USER = useQuery({
    queryKey: ["GET_ALL_USER"],
    queryFn: async () => {
      const result = await GET_ALL_USER();
      return result;
    },
  });
  let newArray =
    DATA_ALL_USER.data?.data?.All_User.map((item, index) => {
      return {
        ...item,
        id: item._id,
      };
    }) || [];

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      newArray
        .map((obj, index) => ({
          ...obj,
          id: obj._id, // Hoặc một giá trị id khác tùy ý bạn
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const handleAccess = async () => {
    try {
      // Sử dụng Promise.all để thực hiện đồng thời 10 lời gọi API
      await Promise.all(
        selectedRow.map((body) =>
          ACCEPT_ACCESS.mutate(body, {
            onSuccess: (data) => {
              alert("UPDATE SUCCESS !");
              DATA_ALL_USER.refetch();
              setSelectedRow([]);
              setSelectionModel([]);
            },
          })
        )
      );
    } catch (error) {
      console.error("Error creating users:", error);
    }
  };

  const handleBANNED = async () => {
    try {
      // Sử dụng Promise.all để thực hiện đồng thời 10 lời gọi API
      await Promise.all(
        selectedRow.map((body) =>
          BANNED.mutate(body, {
            onSuccess: (data) => {
              alert("UPDATE SUCCESS !");
              DATA_ALL_USER.refetch();
              setSelectedRow([]);
              setSelectionModel([]);
            },
          })
        )
      );
    } catch (error) {
      console.error("Error creating users:", error);
    }
  };
  const handleUNVERIFY = async () => {
    try {
      // Sử dụng Promise.all để thực hiện đồng thời 10 lời gọi API
      await Promise.all(
        selectedRow.map((body) =>
          UNVERIFY.mutate(body, {
            onSuccess: (data) => {
              alert("UPDATE SUCCESS !");
              DATA_ALL_USER.refetch();
              setSelectedRow([]);
              setSelectionModel([]);
            },
          })
        )
      );
    } catch (error) {
      console.error("Error creating users:", error);
    }
  };
  return (
    <Box m="20px">
      <Header title="Đại lý" />
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button onClick={handleAccess} color="success">
          <LoginIcon></LoginIcon>
          <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
            {" "}
            DUYỆT
          </Typography>
        </Button>
        <Button onClick={handleUNVERIFY} color="info">
          <CancelScheduleSendIcon color="white"></CancelScheduleSendIcon>
          <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
            {" "}
            HUỶ DUYỆT
          </Typography>
        </Button>
        <Button onClick={handleBANNED} color="error">
          <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
          <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
            {" "}
            CẤM TÀI KHOẢN
          </Typography>
        </Button>
      </ButtonGroup>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={newArray || []}
          columns={columns}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Agency;
