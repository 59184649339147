import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";

import {
  getMonthNameInVietnamese,
  FormatDateYYYYMMDD,
} from "./components/conponents";
import { useEffect, useState } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useMutation, useQuery } from "@tanstack/react-query";

//ADMIN
import {
  Handle_Search_Get_Purchase_Admin,
  Handle_Statistic_Get_Purchase_Admin_By_month_year,
} from "../../apis/handlePurchase";
import { Handle_Statistic_Invite } from "../../apis/handleUpgrade";
//SAN
import { Handle_Statistic_Invite_OF_SAN } from "../../apis/handleUpgrade";
import {
  Handle_Search_Get_Purchase_SAN,
  Handle_Statistic_Get_Purchase_SAN_By_month_year,
} from "../../apis/handlePurchase";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [stateRevenueAdminPurchase, setStateRevenueAdminPurchase] = useState(
    {}
  );
  const [stateRevenueAdminInvite, setStateRevenueAdminInvite] = useState({});
  const [stateRevenueSANPurchase, setStateRevenueSANPurchase] = useState({});
  const [stateRevenueSANInvite, setStateRevenueSANInvite] = useState({});

  //state data Chart by month year for admin
  const [stateRevenueAdminPurchaseChart, setStateRevenueAdminPurchaseChart] =
    useState([]);
  //state data Chart by month year for SAN
  const [stateRevenueSANPurchaseChart, setStateRevenueSANPurchaseChart] =
    useState([]);

  //state min max
  const [stateMinSanPurchase, setStateMinSanPurchase] = useState(0);
  const [stateMaxSanPurchase, setStateMaxSanPurchase] = useState(0);

  const [stateMinADMINPurchase, setStateMinADMINPurchase] = useState(0);
  const [stateMaxADMINPurchase, setStateMaxADMINPurchase] = useState(0);

  //HANDLE STATISTIC ADMIN
  const handle_statistic_revenue_invite = useMutation({
    mutationKey: ["statistic_invite_revenue"],
    mutationFn: (body) => {
      return Handle_Statistic_Invite(body);
    },
  });
  const handle_statistic_revenue_purchase = useMutation({
    mutationKey: ["statistic_purchase_revenue"],
    mutationFn: (body) => {
      return Handle_Search_Get_Purchase_Admin(body);
    },
  });
  const handle_statistic_revenue_purchase_by_month_year = useMutation({
    mutationKey: ["statistic_purchase_ADMIN_revenue_by_month_year"],
    mutationFn: (body) => {
      return Handle_Statistic_Get_Purchase_Admin_By_month_year(body);
    },
  });
  //

  //HANDLE STATISTIC SAN
  const handle_statistic_revenue_invite_SAN = useMutation({
    mutationKey: ["statistic_invite_revenue_SAN"],
    mutationFn: (body) => {
      return Handle_Statistic_Invite_OF_SAN(body);
    },
  });
  const handle_statistic_revenue_purchase_SAN = useMutation({
    mutationKey: ["statistic_purchase_revenue_SAN"],
    mutationFn: (body) => {
      return Handle_Search_Get_Purchase_SAN(body);
    },
  });
  const handle_statistic_revenue_SAN_purchase_by_month_year = useMutation({
    mutationKey: ["statistic_purchase_SAN_revenue_by_month_year"],
    mutationFn: (body) => {
      return Handle_Statistic_Get_Purchase_SAN_By_month_year(body);
    },
  });
  //
  const formattedDate = `${getMonthNameInVietnamese(
    currentDate.getMonth()
  )}-${currentDate.getFullYear()}`;

  const handleIncrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    if (newDate.getMonth() === 0) {
      newDate.setFullYear(newDate.getFullYear());
    }

    setCurrentDate(newDate);
  };
  const handleDecrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);

    if (newDate.getMonth() === 11) {
      newDate.setFullYear(newDate.getFullYear());
    }

    setCurrentDate(newDate);
  };
  //FETCH
  const FetchData_statistic_admin = (startTime, endTime) => {
    handle_statistic_revenue_purchase.mutate(
      {
        startTime: startTime,
        endTime: endTime,
      },
      {
        onSuccess: (data) => {
          if (data?.data) {
            setStateRevenueAdminPurchase(data?.data);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );

    handle_statistic_revenue_invite.mutate(
      {
        startTime: startTime,
        endTime: endTime,
      },
      {
        onSuccess: (data) => {
          if (data?.data?.data) {
            setStateRevenueAdminInvite(data?.data?.data);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );
  };
  const FetchData_statistic_admin_month_year = (month, year) => {
    handle_statistic_revenue_purchase_by_month_year.mutate(
      {
        month: month,
        year: year,
      },
      {
        onSuccess: (data) => {
          if (data?.data) {
            // setStateRevenueAdminPurchase(data?.data);
            setStateRevenueAdminPurchaseChart(data?.data);
            let maxValue = 0;
            let minValue = 0;
            data?.data?.forEach((obj) => {
              if (obj?.total_money > maxValue) {
                maxValue = obj?.total_money;
              }
              if (obj?.total_money < minValue) {
                minValue = obj.total_money;
              }
            });
            setStateMinADMINPurchase(minValue);
            setStateMaxADMINPurchase(maxValue);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );
  };
  const FetchData_statistic_admin_SAN = (startTime, endTime) => {
    handle_statistic_revenue_purchase_SAN.mutate(
      {
        startTime: startTime,
        endTime: endTime,
      },
      {
        onSuccess: (data) => {
          if (data?.data) {
            setStateRevenueSANPurchase(data?.data);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );

    handle_statistic_revenue_invite_SAN.mutate(
      {
        startTime: startTime,
        endTime: endTime,
      },
      {
        onSuccess: (data) => {
          if (data?.data?.data) {
            setStateRevenueSANInvite(data?.data?.data);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );
  };
  const FetchData_statistic_san_month_year = (month, year) => {
    handle_statistic_revenue_SAN_purchase_by_month_year.mutate(
      {
        month: month,
        year: year,
      },
      {
        onSuccess: (data) => {
          if (data?.data) {
            // setStateRevenueAdminPurchase(data?.data);
            setStateRevenueSANPurchaseChart(data?.data);
            let maxValue = 0;
            let minValue = 0;
            data?.data?.forEach((obj) => {
              if (obj?.total_money > maxValue) {
                maxValue = obj?.total_money;
              }
              if (obj?.total_money < minValue) {
                minValue = obj.total_money;
              }
            });
            setStateMinSanPurchase(minValue);
            setStateMaxSanPurchase(maxValue);
          }
        },
        onError: (err) => {
          console.log("err", err);
        },
      }
    );
  };
  //
  useEffect(() => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth());
    if (newDate.getMonth() === 0) {
      newDate.setFullYear(newDate.getFullYear());
    }
    // Ngày bắt đầu của tháng
    const startOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

    // Ngày kết thúc của tháng
    const endOfMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    );
    const startOfMonthFormatted = FormatDateYYYYMMDD(startOfMonth);
    const endOfMonthFormatted = FormatDateYYYYMMDD(endOfMonth);

    FetchData_statistic_admin(startOfMonthFormatted, endOfMonthFormatted);
    FetchData_statistic_admin_SAN(startOfMonthFormatted, endOfMonthFormatted);

    FetchData_statistic_admin_month_year(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
    FetchData_statistic_san_month_year(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
  }, [formattedDate]);
  return (
    <Box
      m="20px"
      height={"91vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "scroll",
      }}
      // display={"flex"}
      // justifyContent={"space-between"}
      // flexDirection={"column"}
    >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Bảng Điều Khiển" />
      </Box>

      <Box
        m="20px 20px 20px 0px"
        width={"400px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          onClick={() => {
            handleDecrease();
          }}
          sx={{ backgroundColor: "green" }}
          variant="contained"
        >
          <ArrowLeftIcon fontSize="large"></ArrowLeftIcon>
        </Button>
        <Typography>{formattedDate} </Typography>
        <Button
          onClick={() => {
            handleIncrease();
          }}
          sx={{ backgroundColor: "green" }}
          variant="contained"
        >
          <ArrowRightIcon fontSize="large"></ArrowRightIcon>
        </Button>
      </Box>
      {/* ROW 1 */}
      <Box>
        <Typography
          variant="h3"
          fontWeight="bold"
          mb={1}
          color={colors.greenAccent[500]}
        >
          Thống Kê Doanh Thu Sàn
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {" "}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(
              stateRevenueSANPurchase?.totalMoney +
                stateRevenueSANInvite?.totalMoney
            ).toLocaleString("en-us")}
            subtitle="Tổng Doanh Thu Sàn (Giá Max)"
            progress="0.75"
            icon={
              <PaidIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(stateRevenueSANPurchase?.totalMoney).toLocaleString(
              "en-us"
            )}
            subtitle="Doanh thu từ đơn hàng"
            icon={
              <ShoppingCartIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(stateRevenueSANInvite?.totalMoney).toLocaleString(
              "en-us"
            )}
            subtitle="Doanh thu từ thành viên"
            icon={
              <WidgetsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>

      <Box
        mt={2}
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap="20px"
        backgroundColor={colors.primary[400]}
      >
        {/* ROW 2 */}

        <Box
          mt="25px"
          p="0 30px"
          display="flex "
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
              Bảng dữ liệu sàn
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box height="450px" m="-20px 0 0 0">
          <LineChart
            isDashboard={true}
            stateDoanhthuADMIN={stateRevenueSANPurchaseChart}
            minAD={stateMinSanPurchase}
            maxAD={stateMaxSanPurchase}
          />
        </Box>
      </Box>
      {/* ROW 2 */}
      <Box mt={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          mb={1}
          color={colors.greenAccent[500]}
        >
          Thống Kê Doanh Thu Admin
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {" "}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(
              stateRevenueAdminPurchase?.totalMoney +
                stateRevenueAdminInvite?.totalMoney
            ).toLocaleString("en-us")}
            subtitle="Tổng Doanh Thu admin (Giá Min)"
            progress="0.75"
            icon={
              <PaidIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(
              stateRevenueAdminPurchase?.totalMoney
            ).toLocaleString("en-us")}
            subtitle="Doanh thu từ đơn hàng"
            icon={
              <ShoppingCartIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={parseInt(stateRevenueAdminInvite?.totalMoney).toLocaleString(
              "en-us"
            )}
            subtitle="Doanh thu từ thành viên"
            icon={
              <WidgetsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>

      {/* row 3 */}
      <Box
        mt={2}
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap="20px"
        backgroundColor={colors.primary[400]}
      >
        {/* ROW 2 */}

        <Box
          mt="25px"
          p="0 30px"
          display="flex "
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
              Bảng dữ liệu admin
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box height="450px" m="-20px 0 0 0">
          <LineChart
            isDashboard={true}
            stateDoanhthuADMIN={stateRevenueAdminPurchaseChart}
            minAD={stateMinADMINPurchase}
            maxAD={stateMaxADMINPurchase}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
