import api from "./apis";
import NProgress from "nprogress";
export const HandleLogin = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/login`,
      {
        email: req.email,
        password: req.password,
        role: parseInt(process.env.REACT_APP_ROLE_ADMIN),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const REGISTER_USER = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/admin/accounts/create`, req, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_ACCOUNT_SELLER = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/sellers`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_ACCOUNT_BY_ROLE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/accounts/get-role`, {
      params: {
        page: req.page,
        limit: req.limit,
        date: req.date,
        roles: req.roles,
        sort: req.sort,
        name: req.name,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_ACCOUNT_UPGRADE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/sellers/get-all-upgrade`, {
      params: {
        page: req.page,
        limit: req.limit,
        sort: req.sort,
        status: req.status,
        code_upgrade: req.code_upgrade,
        date: req.date,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_SELLER_ACCOUNT_OF_SELLER = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/admin/sellers/get-seller-by-ref`,
      { seller_id: req.seller_id },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const VERIFY_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const res = await api.put(
      `/admin/sellers/verify/${req.id}`,
      {
        verify: req.verify,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const ACCEPT_UPGRADE_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/admin/sellers/confirm-upgrade-seller`,
      {
        upgrade_id: req.upgrade_id,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const CHANGEPASS_ACCOUNT = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/admin/accounts/change-password`,
      {
        user_id: req.user_id,
        new_password: req.new_password,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const LOG_OUT = async (req) => {
  try {
    const res = await api.post(
      `/auth/logout`,
      {
        role: parseInt(process.env.REACT_APP_ROLE_ADMIN),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
