import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";
import { REGISTER_USER } from "../../apis/handleAccount";
import { useQuery, useMutation } from "@tanstack/react-query";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [stateErrors, setStateErrors] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [stateFormCreate, setStateFormCreate] = useState({
    name: "",
    email: "",
    password: "",
    repassword: "",
    phone: "",
  });
  const [Role, setRole] = useState(null);

  const CREATE_USER = useMutation({
    mutationFn: (body) => {
      return REGISTER_USER(body);
    },
  });
  const handleChangeValue = (e) => {
    setStateErrors({
      ...stateErrors,
      [e.target.name]: "",
    });
    setStateFormCreate({
      ...stateFormCreate,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeRoles = (event) => {
    setStateErrors({
      ...stateErrors,
      [event.target.name]: "",
    });
    setRole(event.target.value);
  };

  const handleCreateUser = () => {
    setIsloading(true);
    if (validate()) {
      let FormCreate = {
        ...stateFormCreate,
        roles: Role,
      };
      CREATE_USER.mutate(FormCreate, {
        onSuccess: (data) => {
          if (!data?.errors) {
            setStateErrors({});
            setStateFormCreate({
              name: "",
              email: "",
              password: "",
              repassword: "",
              phone: "",
            });
            alert("Create success");
          } else {
            setStateErrors({
              email: data?.errors?.email?.msg,
            });
          }
          setIsloading(false);
        },
        onError: (err) => {
          console.log("err " + err);
          setIsloading(false);
        },
      });
    } else {
      setIsloading(false);
    }
  };
  const validate = () => {
    const newError = {};

    //validate required
    if (!stateFormCreate.name) {
      newError.name = "Vui lòng không để trống thông tin";
    }
    if (!stateFormCreate.email) {
      newError.email = "Vui lòng không để trống thông tin";
    }
    if (!stateFormCreate.password) {
      newError.password = "Mật khẩu không để trống";
    }
    if (!stateFormCreate.repassword) {
      newError.repassword = "Mật khẩu không để trống";
    }
    if (!stateFormCreate.phone) {
      newError.phone = "Số điện thoại không để trống";
    }
    if (!Role && Role != 0) {
      newError.Role = "* Chức vụ không để trống";
    }
    //validate option
    if (stateFormCreate.phone) {
      stateFormCreate.phone = stateFormCreate.phone.trim();

      var phoneRegex = /^0\d{9}$/;

      if (!phoneRegex.test(stateFormCreate.phone)) {
        newError.phone = "Số điện thoại không hợp lệ";
      }
    }
    if (stateFormCreate.email) {
      stateFormCreate.email = stateFormCreate.email.trim();

      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(stateFormCreate.email)) {
        newError.email = "Email không hợp lệ";
      }
    }
    if (stateFormCreate.password) {
      // Xóa khoảng trắng ở đầu và cuối chuỗi
      stateFormCreate.password = stateFormCreate.password.trim();

      // Biểu thức chính quy để kiểm tra chuỗi
      var lengthRegex = /^.{6,}$/; // Ít nhất 6 ký tự
      var uppercaseRegex = /[A-Z]/; // Ít nhất một chữ in hoa
      var specialCharRegex = /[!@#$%^&*(),.?":{}|<>_\-=+]/; // Ít nhất một ký tự đặc biệt

      // Kiểm tra chuỗi
      if (!lengthRegex.test(stateFormCreate.password)) {
        newError.password = "Mật khẩu phải có ít nhất 6 ký tự";
      } else if (!uppercaseRegex.test(stateFormCreate.password)) {
        newError.password = "Mật khẩu phải có ký tự In hoa !!";
      } else if (!specialCharRegex.test(stateFormCreate.password)) {
        newError.password = "Mật khẩu phải có ký tự đặc biệt !!";
      }
    }
    if (stateFormCreate.repassword) {
      if (
        stateFormCreate.repassword.trim() !== stateFormCreate.password.trim()
      ) {
        newError.repassword = "Mật khẩu không khớp !";
      }
    }

    setStateErrors(newError);

    return Object.keys(newError).length === 0;
  };
  return (
    <Box m="20px">
      <Button onClick={handleCreateUser}>create</Button>
      <Header title="Thông Tin Tài Khoản" subtitle="" />

      <Formik initialValues={initialValues} validationSchema={checkoutSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form
            // style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
            onSubmit={handleSubmit}
          >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Chức vụ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Role}
                  label="Chức vụ"
                  onChange={handleChangeRoles}
                >
                  {/* <MenuItem value={0}>ADMIN</MenuItem> */}
                  <MenuItem value={1}>SELLER</MenuItem>
                  <MenuItem value={2}>PDP</MenuItem>
                </Select>
                {stateErrors?.Role ? (
                  <Typography color={"red"}>{stateErrors.Role}</Typography>
                ) : (
                  ""
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.email}
                error={stateErrors?.email || ""}
                helperText={stateErrors?.email || ""}
                name="email"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Tên doanh nghiệp"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.name}
                error={stateErrors?.name || ""}
                helperText={stateErrors?.name || ""}
                name="name"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Số điện thoại"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.phone}
                error={stateErrors?.phone || ""}
                helperText={stateErrors?.phone || ""}
                name="phone"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Mật khẩu"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.password}
                error={stateErrors?.password || ""}
                helperText={stateErrors?.password || ""}
                name="password"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Xác nhận lại mật khẩu"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.repassword}
                error={stateErrors?.repassword || ""}
                helperText={stateErrors?.repassword || ""}
                name="repassword"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {isLoading ? (
                <Button color="secondary" variant="contained">
                  <CircularProgress
                    sx={{ fontSize: "10px" }}
                  ></CircularProgress>
                </Button>
              ) : (
                <Button
                  onClick={handleCreateUser}
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: "1rem",
                    // color: "white",
                    fontWeight: "600",
                  }}
                >
                  KHỞI TẠO
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  password: yup.string().required("required"),
  RePassword: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  password: "",
  RePassword: "",
};

export default Form;
