export const getMonthNameInVietnamese = (month) => {
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  return monthNames[month];
};

export const FormatDateYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Thêm 0 vào tháng nếu cần
  const day = String(date.getDate()).padStart(2, "0"); // Thêm 0 vào ngày nếu cần
  return `${year}-${month}-${day}`;
};
