import { useParams } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";

import { GET_PRODUCT_BY_ID } from "../../apis/handleProduct";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Image } from "@mui/icons-material";
const ProductDetails = () => {
  const { productid } = useParams();
  const [stateProduct, setStateProduct] = useState({});
  const GET_PRODUCT = useQuery({
    queryKey: ["GET_ONE_PRODUCT"],
    queryFn: async () => {
      const result = await GET_PRODUCT_BY_ID(productid);
      if (result?.data?.data) {
        setStateProduct(result?.data?.data);
      }
      return result; // Ensure the result is returned
    },
  });
  console.log("GET_PRODUCT.isFetching " + GET_PRODUCT.isFetching);
  return (
    <>
      <Box pl={3} width={"100%"} height={"100%"}>
        <Box className="header-details">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/#/san-pham">
              Sản phẩm
            </Link>

            <Typography color="text.primary">Chi tiết sản phẩm</Typography>
          </Breadcrumbs>
        </Box>
        {GET_PRODUCT.isFetching ? (
          <Box display={"flex"} width={"100%"} height={"100%"}>
            <Box margin={"auto"} fontSize={"1.2rem"}>
              Đang trong quá trình lấy dữ liệu sản phẩm vui lòng chờ.....
            </Box>
          </Box>
        ) : (
          <>
            {" "}
            {stateProduct?._id ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Box
                  className="Product-images"
                  sx={{
                    display: "flex",
                    backgroundColor: "#1f2a40",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <Card
                    className="Product-details"
                    sx={{
                      width: "100%",
                      padding: "1rem",
                      backgroundColor: "#1f2a40",
                    }}
                  >
                    <Typography variant="h4">Hình ảnh sản phẩm</Typography>
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "20px",
                      }}
                    >
                      {stateProduct?.images &&
                        stateProduct?.images.map((item, index) => (
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="300"
                              image={item.path}
                              alt="Sản phẩm 1"
                            />
                          </Card>
                        ))}
                    </CardContent>
                  </Card>
                </Box>
                <Box
                  className="Product-images"
                  sx={{
                    display: "flex",
                    backgroundColor: "#1f2a40",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <Card
                    className="Product-details"
                    sx={{
                      width: "100%",
                      backgroundColor: "#1f2a40",
                      padding: "1rem",
                    }}
                  >
                    <Typography variant="h4">Hoá đơn nhập kho</Typography>
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "20px",
                      }}
                    >
                      {stateProduct?.invoice_images &&
                        stateProduct?.invoice_images.map((item, index) => (
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={item.path}
                              alt="Sản phẩm 1"
                            />
                          </Card>
                        ))}
                    </CardContent>
                  </Card>
                </Box>
                <Card
                  className="Product-details"
                  sx={{
                    width: "100%",
                    backgroundColor: "#1f2a40",
                    padding: "1rem",
                  }}
                >
                  <Box className="row">
                    <Box className="col-xs-12 col-md-4">
                      {" "}
                      <Typography variant="h4">Thông tin sản phẩm</Typography>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Tên sản phẩm: {stateProduct?.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Mô tả: {stateProduct?.description}
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                          Giá gốc:{" "}
                          {parseInt(
                            stateProduct?.price_original
                          ).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                          Giá bán:{" "}
                          {parseInt(
                            stateProduct?.price_for_customer
                          ).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </Typography>

                        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                          Lượt xem: {stateProduct?.view}
                        </Typography>

                        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                          Lợi nhuận:{" "}
                          {parseInt(
                            stateProduct?.profit_for_pdp
                          ).toLocaleString("it-IT", {
                            style: "currency",
                            currency: "VND",
                          }) || "Đang trong quá trình cập nhật ..."}{" "}
                        </Typography>
                      </CardContent>
                    </Box>

                    {/* <Box className="col-xs-12 col-md-4">
                      {" "}
                      <Typography variant="h4">
                        Tên kho : {stateProduct?.store?.name}
                      </Typography>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Số lượng tồn kho: {stateProduct?.store?.stock}
                        </Typography>
                      </CardContent>
                    </Box> */}

                    <Box className="col-xs-12 col-md-4">
                      {" "}
                      <Typography variant="h4">
                        Tồn kho vị trí : {stateProduct?.store_company?.name}
                      </Typography>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Số lượng tồn kho: {stateProduct?.store_company?.stock}
                        </Typography>
                      </CardContent>
                    </Box>

                    <Box className="col-xs-12 col-md-4">
                      {" "}
                      <Typography variant="h4">
                        Nội dung quảng cáo cho đại lý
                      </Typography>
                      <CardContent>
                        <Typography
                          variant="h5"
                          color="text.secondary"
                          component="div"
                        >
                          {stateProduct?.content}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ) : (
              <Box display={"flex"} width={"100%"} height={"100%"}>
                <Box margin={"auto"} fontSize={"1.2rem"}>
                  Sản phẩm này không tìm thấy, hoặc đã bị xoá
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default ProductDetails;
