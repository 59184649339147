import { Button, ButtonGroup, Typography } from "@mui/material";
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};
export const Columns = (handleOpen) => [
  { field: "id", flex: 1, headerName: "Số thứ tự" },
  { field: "name", flex: 2, headerName: "Tên sản phẩm" },
  {
    field: "_id",
    flex: 2,
    headerName: "Điều chỉnh",
    renderCell: (params) => {
      return (
        <>
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button
              onClick={() => handleOpen(params?.value)}
              sx={{ color: "white", fontSize: "1rem", fontWeight: "600" }}
              color="warning"
            >
              Sửa
            </Button>
          </ButtonGroup>
        </>
      );
    },
  },
  {
    field: "image",
    headerName: "Hình ảnh",
    width: 200,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <img
            src={params?.value}
            alt="Hình ảnh"
            style={{ width: "100px", height: "auto", objectFit: "cover" }}
          />
        ) : (
          "No Images"
        )}
      </div>
    ),
  },
];
