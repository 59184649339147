import api from "./apis";
import NProgress from "nprogress";
export const GET_ALL_TRANSACTION_ACCOUNT_UPGRADE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/payments/get-all-transaction`, {
      params: {
        page: req.page,
        limit: req.limit,
        sort: req.sort,
        status: req.status,
        transaction_code: req.transaction_code,
        date: req.date,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const CONFIRM_TRANSACTION = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/admin/payments/confirm-transaction`, {
      code: req.code,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
