import { Box, Typography, useTheme } from "@mui/material";
import DatePicker from "react-date-picker";
import SearchIcon from "@mui/icons-material/Search";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomToolbar } from "../../components/function";
import { tokens } from "../../theme";
import { Handle_Statistic_Invite } from "../../apis/handleUpgrade";
import { useQuery, useMutation } from "@tanstack/react-query";
import { columnsSearch } from "./components/components";
const SearchStatisticUpgrade = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const [dateF, onChangeF] = useState(null);
  const [dateT, onChangeT] = useState(formattedDate);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);
  const [stateSearch, setStateSearch] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const HANDLE_SEARCH = useMutation({
    mutationFn: (body) => {
      return Handle_Statistic_Invite(body);
    },
  });
  const handleSearch = () => {
    let formSearch = {
      startTime: dateF,
      endTime: dateT,
    };
    HANDLE_SEARCH.mutate(formSearch, {
      onSuccess: (data) => {
        if (data?.data?.data) {
          setStateSearch(data?.data?.data);
          setStateListUpgrade(data?.data?.data?.upgrade);
        }
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };
  return (
    <>
      <Box
        mt={2}
        display={"flex"}
        width={"600px"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontSize={"1.1rem"}>Ngày bắt đầu (YYYY-MM-DD)</Typography>
          <DatePicker
            clearIcon={null}
            format="y-MM-dd"
            onChange={(e) => {
              let year = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[0];
              let Month = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[1];
              let checkday = parseInt(
                JSON.stringify(e).replace('"', "").split("T")[0].split("-")[2]
              );

              let day = parseInt(checkday).toString().padStart(2, "0");

              let Formdate = year + "-" + Month + "-" + day;
              onChangeF(Formdate);
            }}
            value={dateF}
          />
        </Box>
        <Box>
          <Typography fontSize={"1.1rem"}>
            Ngày Kết thúc (YYYY-MM-DD)
          </Typography>
          <DatePicker
            clearIcon={null}
            format="y-MM-dd"
            onChange={(e) => {
              let year = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[0];
              let Month = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[1];

              let checkday = parseInt(
                JSON.stringify(e).replace('"', "").split("T")[0].split("-")[2]
              );

              let day = parseInt(checkday).toString().padStart(2, "0");

              let Formdate = year + "-" + Month + "-" + day;
              onChangeT(Formdate);
            }}
            value={dateT}
          />
        </Box>

        <Box>
          <button onClick={handleSearch} className="btn btn-info">
            <SearchIcon sx={{ color: "white" }} fontSize="medium"></SearchIcon>
          </button>
        </Box>
      </Box>
      <Box>
        <Typography alignItems={"center"} display={"flex"}>
          <Typography fontSize={"1.1rem"}> Doanh thu trong khoảng:</Typography>

          <Typography ml={1} fontSize={"1rem"} color={"yellowgreen"}>
            {" "}
            {dateF ? dateF : ""}
            <ArrowRightAltIcon sx={{ color: "white" }}></ArrowRightAltIcon>{" "}
            {dateT ? dateT : ""}
          </Typography>
        </Typography>
        <Box alignItems={"center"} display={"flex"}>
          <Typography alignItems={"center"} display={"flex"}>
            <LocalAtmIcon fontSize="large" color="success"></LocalAtmIcon>
            <Typography ml={1} fontSize={"1.1rem"}>
              Tổng doanh thu :
            </Typography>
          </Typography>
          <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
            {stateSearch?.totalMoney
              ? parseInt(stateSearch?.totalMoney).toLocaleString("en-us")
              : 0}
          </Typography>
        </Box>

        <Box alignItems={"center"} display={"flex"}>
          <Typography alignItems={"center"} display={"flex"}>
            <Inventory2Icon fontSize="large" color="success"></Inventory2Icon>
            <Typography ml={1} fontSize={"1.1rem"}>
              Tổng đơn yêu cầu :
            </Typography>
          </Typography>
          <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
            {stateListUpgrade ? stateListUpgrade.length : 0}
          </Typography>
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={
            (stateListUpgrade &&
              stateListUpgrade?.map((item, index) => {
                return {
                  ...item,
                  id: item._id,
                };
              })) ||
            []
          }
          columns={columnsSearch}
          paginationMode="server"
          checkboxSelection
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </>
  );
};
export default SearchStatisticUpgrade;
