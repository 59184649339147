import { Avatar, Box, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import PolicyIcon from "@mui/icons-material/Policy";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
export const columns = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   flex: 0.5,
  //   renderCell: (params) => (
  //     <a href={`/#/tai-khoan-tham-gia-seller/${params.row.id}`}>
  //       {params.value}
  //     </a>
  //   ),
  // },
  {
    field: "name",
    headerName: "TÊN TÀI KHOẢN",
    flex: 1,
  },

  {
    field: "phone",
    headerName: "SỐ ĐIỆN THOẠI",
    flex: 0.5,
  },
  {
    field: "email",
    headerName: "EMAIL",
    flex: 0.5,
  },
  {
    field: "avatar",
    headerName: "ẢNH ĐẠI DIỆN",
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <Avatar src={params?.value} sx={{ width: 70, height: 70 }}></Avatar>
        ) : (
          "Chưa có ảnh"
        )}
      </div>
    ),
  },

  {
    field: "verify",
    headerName: "XÁC THỰC",
    flex: 1,
    renderCell: (params) => (
      <>
        {params.value === parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED) ? (
          <Typography
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            variant="h5"
            p={1}
            bgcolor={"GrayText"}
            fontWeight={"600"}
          >
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              width={"60%"}
            >
              <PolicyIcon></PolicyIcon>{" "}
              <Typography fontWeight={"600"} variant="h5">
                {" "}
                Chưa duyệt
              </Typography>
            </Box>
          </Typography>
        ) : params.value ===
          parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED) ? (
          <Typography
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            variant="h5"
            p={1}
            bgcolor={"green"}
            fontWeight={"600"}
          >
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              width={"60%"}
            >
              <SecurityIcon></SecurityIcon>{" "}
              <Typography fontWeight={"600"} variant="h5">
                {" "}
                Đã duyệt
              </Typography>
            </Box>
          </Typography>
        ) : (
          <Typography
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            variant="h5"
            p={1}
            bgcolor={"red"}
            fontWeight={"600"}
          >
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              width={"60%"}
            >
              <RemoveModeratorIcon></RemoveModeratorIcon>{" "}
              <Typography fontWeight={"600"} variant="h5">
                {" "}
                Ngưng hoạt động
              </Typography>
            </Box>
          </Typography>
        )}
      </>
    ),
  },
  // {
  //   field: "roles",
  //   headerName: "CHỨC VỤ",
  //   flex: 1,
  // },
];
export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
