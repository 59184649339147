import React, { useState } from "react";

import "./style.css";

import { useNavigate } from "react-router-dom";
import Desktopscreen from "./desktopscreen";

const Login = () => {
  console.log("check e 1" + process.env.REACT_APP_ROLE_ADMIN);
  console.log("check e 2" + process.env.REACT_APP_ROLE_SELLER);
  console.log("check e 3" + process.env.REACT_APP_ROLE_PDP);
  return (
    <>
      <Desktopscreen></Desktopscreen>
    </>
  );
};

export default Login;
