import api from "./apis";
import NProgress from "nprogress";
export const GET_ALL_CATEGORY = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/categories`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_PRODUCT = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/products/get-all`, {
      params: {
        page: req.page,
        limit: req.limit,
        date: "latest",
        sort: "asc",
        status: ""
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_PRODUCT_BY_ID = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/products/get-by-id/${req}`);

    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_PRODUCT_BY_PROVIDERID = async (req) => {

  try {
    NProgress.start();
    const res = await api.post(
      `/admin/products?limit=${req?.limit}&page=${req?.page}&date=latest&sort=asc&status=&destroy=asc&name=`,
      {
        provider_id: req?.provider_id
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_Provider = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/accounts/get-all-providers`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Create_Product = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(`/admin/products`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("tk")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Update_Product = async (req) => {
  try {
    NProgress.start();
    const respod = await api.put(`/admin/products/${req.id}`, req, {
      headers: {
        "Content-Type": "application/json",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Update_Product_Status = async (req) => {
  try {
    NProgress.start();
    const respod = await api.put(
      `/admin/products/change-status/${req.id}`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Delete_Product = async (req) => {
  try {
    NProgress.start();
    const respod = await api.delete(`/admin/products/delete/${req.id}`, {
      headers: {
        "Content-Type": "application/json",
        // Thêm các header khác nếu cần
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CREATE_CATEGORY = async (req) => {
  try {
    const res = await api.post(`/admin/categories`, {
      name: req.name,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
export const UPDATE_CATEGORY = async (req) => {
  try {
    const res = await api.put(`/admin/categories/${req._id}`, {
      name: req.name,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
