// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/bghomepage.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n\r\n  \r\n  button:hover {\r\n    background-color: #45a049;\r\n  }\r\n  .bgleft {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: inherit;\r\n    object-fit: contain;  \r\n    height: 100%;\r\n    width: 100%;\r\n  }", "",{"version":3,"sources":["webpack://./src/scenes/login/style.css"],"names":[],"mappings":";;;EAGE;IACE,yBAAyB;EAC3B;EACA;IACE,yDAAwD;IACxD,4BAA4B;IAC5B,sBAAsB;IACtB,4BAA4B;IAC5B,mBAAmB;IACnB,YAAY;IACZ,WAAW;EACb","sourcesContent":["\r\n\r\n  \r\n  button:hover {\r\n    background-color: #45a049;\r\n  }\r\n  .bgleft {\r\n    background-image: url(\"../../assets/img/bghomepage.jpg\");\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: inherit;\r\n    object-fit: contain;  \r\n    height: 100%;\r\n    width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
