import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";

export const CaseDatetimeToDDMMYY = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice(-2);
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${day}-${month}-${year}`;
  return formattedDatetime;
};

export const CaseDatetimeToYYYYMMDD = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice();
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${year}-${month}-${day}`;
  return formattedDatetime;
};

export const CaseDatetimeToYYYYMMDDHHMMSS = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice();
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${year}-${month}-${day} - ${hours}:${minutes}:${seconds}`;
  return formattedDatetime;
};

export const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      <GridToolbarContainer></GridToolbarContainer>
      {/* Bạn có thể thêm các nút hoặc component tùy chỉnh khác tại đây */}
    </GridToolbarContainer>
  );
};
export const CloseModal = ({ handleclose }) => {
  return (
    <>
      {" "}
      <div
        style={{
          position: "absolute",
          objectFit: "cover",
          top: 4,
          right: 4,
          cursor: "pointer",
        }}
        onClick={() => {
          handleclose();
        }}
      >
        <CancelIcon sx={{ color: "GrayText" }} fontSize="large"></CancelIcon>
      </div>
    </>
  );
};
