import api from "./apis";
import NProgress from "nprogress";
export const Handle_Statistic_Invite = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/admin/statistics/statistic-revenues-invite`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Statistic_Invite_OF_SAN = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/admin/statistics/statistic-revenues-invite-by-admin`,
      {
        user_id: process.env.REACT_APP_ID_SAN,
        startTime: req.startTime,
        endTime: req.endTime,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
