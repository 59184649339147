import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GET_ALL_CATEGORY } from "../../apis/handleProduct";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import "./style.css";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Label,
  Input,
  style,
  Columns as columns,
  notification_status_accept,
  notification_status_pending,
  notification_status_cancel,
  notification_Edit_success,
} from "./components/components";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Create_Product,
  GET_ALL_PRODUCT,
  Update_Product,
  Delete_Product,
  GET_ALL_Provider,
  GET_ALL_PRODUCT_BY_PROVIDERID,
  Update_Product_Status,
} from "../../apis/handleProduct";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import LoginIcon from "@mui/icons-material/Login";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import CancelIcon from "@mui/icons-material/Cancel";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Store } from "react-notifications-component";
import { CloseModal, CustomToolbar } from "../../components/function";
import { SearchComponent } from "../../components/components";

const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsEdit, setErrorsEdit] = useState({});
  const [stateProductEdit, setStateProductEdit] = useState({});
  const [Isloading, setLoading] = useState(false);
  const [stateName, setStateName] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [statePage, setStatePage] = useState(0);
  const [statePageProvider, setStatePageProvider] = useState(0);
  const [stateLimit, setStateLimit] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [stateProvider, setStateProvider] = useState([]);
  const [stateProduct, setStateProduct] = useState([]);
  const [stateProviderID, setStateProviderID] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [IsloadingHandle, setIsloading] = useState({
    loading_accept: false,
    loading_pending: false,
    loading_cancel: false,
  });
  const getRowClassName = (params) => {
    return selectionModel.includes(params.id) ? "red-bg" : "";
  };

  const handleOpenEdit = () => {
    setStateProductEdit({
      ...selectedRow[0],
      category: selectedRow[0].category._id,
    });
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    images: null,
    imagesSrc: [],
  });
  const InitFormData = () => {
    setFormData({
      name: "",
      description: "",
      category: "",
      images: null,
      imagesSrc: [],
    });
  };
  const mutation_create = useMutation({
    mutationFn: (body) => {
      return Create_Product(body);
    },
  });
  const mutation_Get_all_product_of_provider = useMutation({
    mutationFn: (body) => {
      return GET_ALL_PRODUCT_BY_PROVIDERID(body);
    },
  });
  const mutation_Update = useMutation({
    mutationFn: (body) => {
      return Update_Product(body);
    },
  });
  const mutation_Update_Status = useMutation({
    mutationFn: (body) => {
      return Update_Product_Status(body);
    },
  });
  const DataCateGory = useQuery({
    queryKey: ["GetAllCategory"],
    queryFn: async () => {
      const result = await GET_ALL_CATEGORY();
      return result; // Ensure the result is returned
    },
  });

  const Data_get_all_provider = useQuery({
    queryKey: ["get_all_provider"],
    queryFn: async () => {
      const result = await GET_ALL_Provider();
      if (result) {
        let getall = {
          id: "111",
          check: "getall",
          label: "--LẤY RA TẤT CẢ---",
        };

        let newArrayObject = result?.data?.data.map((obj) => {
          return { ...obj, label: obj.name };
        });
        newArrayObject.push(getall);
        setStateProvider(newArrayObject);
      }
      return result; // Ensure the result is returned
    },
  });

  let newArray = stateProduct.map((item, index) => {
    return {
      ...item,
      id: item._id,
    };
  });

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      newArray
        .map((obj, index) => ({
          ...obj,
          id: obj._id, // Hoặc một giá trị id khác tùy ý bạn
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const handleChange = (e) => {
    setErrors({});
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeEdit = (e) => {
    setErrorsEdit({});
    setErrors({});
    setStateProductEdit({
      ...stateProductEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    setErrors({});

    if (files) {
      const fileArray = Array.from(files);

      setFormData({
        ...formData,
        images: fileArray,
      });
      // Xóa tất cả ảnh trước đó
      setFormData((prevFormData) => ({
        ...prevFormData,
        imagesSrc: [],
      }));
      fileArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            imagesSrc: [...(prevFormData.imagesSrc || []), e.target.result],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
  };
  const validate = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    if (!formData.images) {
      newErrors.images = "File is required";
    }
    if (formData?.imagesSrc.length > 4) {
      newErrors.imagesSrc = "Max 4 picture";
    }
    if (!formData.category) {
      newErrors.category = "Category is required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const validateEdit = () => {
    const newErrors = {};

    // if (!stateProductEdit.name) {
    //   newErrors.name = "Name is required";
    // }

    // if (!stateProductEdit.description) {
    //   newErrors.description = "Description is required";
    // }

    if (
      parseInt(stateProductEdit.price_original) >=
      parseInt(stateProductEdit.price_retail)
    ) {
      newErrors.price_retail = "*Giá bán lẻ giá trị phải lớn hơn giá gốc !!!";
    }

    if (
      parseInt(stateProductEdit.discount_for_admin) +
        parseInt(stateProductEdit.discount_for_seller) >
      100
    ) {
      newErrors.discount = "Tổng 2 chiết khấu phải không quá 100%";
    }
    setErrorsEdit(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      const dataForm = new FormData();
      dataForm.append("name", formData.name);
      dataForm.append("description", formData.description);
      dataForm.append("category", formData.category);
      formData.images.forEach((file) => {
        dataForm.append("images", file);
      });

      mutation_create.mutate(dataForm, {
        onSuccess: (data) => {
          if (data.data?._id) {
            alert("Thêm sản phẩm thành công ");
            InitFormData();
            setLoading(false);
          }
        },
      });
    } else {
      setLoading(false);
    }
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateEdit()) {
      let formEdit = {
        id: stateProductEdit?._id,
        price_retail: parseInt(stateProductEdit?.price_retail),

        discount_for_admin: parseInt(stateProductEdit?.discount_for_admin),
        discount_for_seller: parseInt(stateProductEdit?.discount_for_seller),
      };
      mutation_Update.mutate(formEdit, {
        onSuccess: (data) => {
          if (data?.data) {
            Store.addNotification(
              notification_Edit_success({
                title: "Điều chỉnh thành công!",
                message: "",
              })
            );
            if (stateProviderID) {
              let formGet = {
                limit: stateLimit,
                page: statePageProvider + 1,
                provider_id: stateProviderID,
                name: stateName,
              };
              mutation_Get_all_product_of_provider.mutate(formGet, {
                onSuccess: (data) => {
                  if (data?.data) {
                    setStateProduct(data?.data?.data?.products);
                    setRowCount(parseInt(data?.data?.data?.total_product));
                  }
                },
              });
            } else {
              // FIRST_GET_ALL_PRODUCT.refetch();
              let formGet = {
                limit: stateLimit,
                page: statePageProvider + 1,
                provider_id: "",
                name: stateName,
              };

              mutation_Get_all_product_of_provider.mutate(formGet, {
                onSuccess: (data) => {
                  if (data?.data) {
                    setStateProduct(data?.data?.data?.products);
                    setRowCount(parseInt(data?.data?.data?.total_product));
                  }
                },
              });
            }

            setLoading(false);
            setOpenEdit(false);
            setStateProductEdit({});
            setSelectedRow([]);
            setSelectionModel([]);
          } else {
            alert("Vui lòng thử lại !!");
            setLoading(false);
          }
        },
      });
    } else {
      setLoading(false);
    }
  };
  const handleChangeGetProvider = (e, newValue) => {
    let formGet = {
      limit: stateLimit,
      page: 1,
      provider_id: newValue._id,
      name: stateName,
    };
    if (newValue?.check) {
      formGet = {
        ...formGet,
        provider_id: "",
      };
      setStateProviderID("");
    } else {
      setStateProviderID(newValue?._id);
    }
    setStatePageProvider(0);

    mutation_Get_all_product_of_provider.mutate(formGet, {
      onSuccess: (data) => {
        if (data?.data) {
          setStateProduct(data?.data?.data?.products);
          setRowCount(parseInt(data?.data?.data?.total_product));
        }
      },
    });
  };
  useEffect(() => {
    let formGet = {
      limit: stateLimit,
      page: 1,
      provider_id: "",
      name: stateName,
    };
    mutation_Get_all_product_of_provider.mutate(formGet, {
      onSuccess: (data) => {
        if (data?.data) {
          setStateProduct(data?.data?.data?.products);
          setRowCount(parseInt(data?.data?.data?.total_product));
        }
      },
    });
  }, []);

  const onHandleChangePage = (e) => {
    let formGet = {
      limit: stateLimit,
      page: e + 1,
      provider_id: "",
      name: stateName,
    };
    if (stateProviderID) {
      formGet = {
        ...formGet,
        provider_id: stateProviderID,
      };
    }
    setStatePageProvider(e);
    mutation_Get_all_product_of_provider.mutate(formGet, {
      onSuccess: (data) => {
        if (data?.data) {
          setStateProduct(data?.data?.data?.products);
          setRowCount(parseInt(data?.data?.data?.total_product));
        }
      },
    });
  };
  const onHandleChangePageSize = (e) => {
    let formGet = {
      limit: e,
      page: statePageProvider + 1,
      provider_id: "",
      name: stateName,
    };
    if (stateProviderID) {
      formGet = {
        ...formGet,
        provider_id: stateProviderID,
      };
    }
    setStateLimit(e);
    mutation_Get_all_product_of_provider.mutate(formGet, {
      onSuccess: (data) => {
        if (data?.data) {
          setStateProduct(data?.data?.data?.products);
          setRowCount(parseInt(data?.data?.data?.total_product));
        }
      },
    });
  };
  const handleDelete = async () => {
    const productPromises = [];
    selectedRow.forEach((item, index) => {
      let formDeleted = {
        id: item.id,
        // category_Id: item.category_Id._id,
      };
      productPromises.push(Delete_Product(formDeleted));
    });
    try {
      await Promise.all(productPromises);
      alert("Xoá thành công");
    } catch (error) {
      console.error("Error creating products:", error);
    }
  };

  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn sản phẩm");
    } else {
      try {
        setIsloading({
          ...IsloadingHandle,
          loading_accept: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            mutation_Update_Status.mutate(
              {
                id: body._id,
                status: parseInt(process.env.REACT_APP_PRODUCT_STATUS_ACCEPT),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_status_accept);
                  if (stateProviderID) {
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: stateProviderID,
                      name: stateName,
                    };
                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  } else {
                    // FIRST_GET_ALL_PRODUCT.refetch();
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: "",
                      name: stateName,
                    };

                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  }

                  setIsloading({
                    ...Isloading,
                    loading_accept: false,
                  });
                  // setSelectedRow([]);
                  // setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_accept: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handlePending = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn sản phẩm");
    } else {
      try {
        setIsloading({
          ...IsloadingHandle,
          loading_pending: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            mutation_Update_Status.mutate(
              {
                id: body._id,
                status: parseInt(process.env.REACT_APP_PRODUCT_STATUS_PENDING),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_status_pending);
                  if (stateProviderID) {
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: stateProviderID,
                      name: stateName,
                    };
                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  } else {
                    // FIRST_GET_ALL_PRODUCT.refetch();
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: "",
                      name: stateName,
                    };

                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  }
                  setIsloading({
                    ...Isloading,
                    loading_pending: false,
                  });
                  // setSelectedRow([]);
                  // setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_pending: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleCancel = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn sản phẩm");
    } else {
      try {
        setIsloading({
          ...IsloadingHandle,
          loading_cancel: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            mutation_Update_Status.mutate(
              {
                id: body._id,
                status: parseInt(process.env.REACT_APP_PRODUCT_STATUS_CANCEL),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_status_cancel);
                  if (stateProviderID) {
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: stateProviderID,
                      name: stateName,
                    };
                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  } else {
                    // FIRST_GET_ALL_PRODUCT.refetch();
                    let formGet = {
                      limit: stateLimit,
                      page: statePageProvider + 1,
                      provider_id: "",
                      name: stateName,
                    };

                    mutation_Get_all_product_of_provider.mutate(formGet, {
                      onSuccess: (data) => {
                        if (data?.data) {
                          setStateProduct(data?.data?.data?.products);
                          setRowCount(
                            parseInt(data?.data?.data?.total_product)
                          );
                        }
                      },
                    });
                  }
                  setIsloading({
                    ...Isloading,
                    loading_cancel: false,
                  });
                  // setSelectedRow([]);
                  // setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_cancel: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleSearch = () => {
    let formGet = {
      limit: stateLimit,
      page: statePageProvider + 1,
      provider_id: stateProviderID,
      name: stateName,
    };
    mutation_Get_all_product_of_provider.mutate(formGet, {
      onSuccess: (data) => {
        if (data?.data) {
          setStateProduct(data?.data?.data?.products);
          setRowCount(parseInt(data?.data?.data?.total_product));
        }
      },
    });
  };
  return (
    <>
      <Box
        height={"90vh"}
        sx={{
          overflowX: "hidden",

          overflowY: "auto",
        }}
        m="20px"
      >
        <Header title="SẢN PHẨM" />
        <Box className="row">
          <Box className="col-sx-12 col-md-6">
            <Typography mb={1} variant="h5">
              Bảng điều khiển
            </Typography>
            <Box display={"flex"}>
              <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button
                  color="warning"
                  onClick={handleOpenEdit}
                  disabled={selectionModel.length !== 1}
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Điều chỉnh sản phẩm
                </Button>
              </ButtonGroup>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={stateProvider || []}
                onChange={handleChangeGetProvider}
                sx={{ width: 300, marginLeft: "2rem" }}
                renderInput={(params, index) => (
                  <TextField key={index} {...params} label="CHỌN PDP" />
                )}
              />
            </Box>
          </Box>

          <Box className="col-sx-12 col-md-6">
            {" "}
            <Typography mb={1} variant="h5">
              Bảng điều chỉnh trạng thái
            </Typography>
            <ButtonGroup variant="contained" aria-label="Basic button group">
              {IsloadingHandle.loading_accept ? (
                <Button color="success">
                  <LoginIcon sx={{ color: "white" }}></LoginIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
                  >
                    {" "}
                    <CircularProgress />
                  </Typography>
                </Button>
              ) : (
                <Button onClick={handleAccess} color="success">
                  <LoginIcon sx={{ color: "white" }}></LoginIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
                  >
                    {" "}
                    DUYỆT
                  </Typography>
                </Button>
              )}
              {IsloadingHandle.loading_pending ? (
                <Button color="info">
                  <CancelScheduleSendIcon
                    sx={{ color: "white" }}
                  ></CancelScheduleSendIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
                  >
                    {" "}
                    <CircularProgress />
                  </Typography>
                </Button>
              ) : (
                <Button color="warning" onClick={handlePending}>
                  <CancelScheduleSendIcon
                    sx={{ color: "white" }}
                  ></CancelScheduleSendIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
                  >
                    {" "}
                    Chờ duyệt
                  </Typography>
                </Button>
              )}
              {IsloadingHandle.loading_cancel ? (
                <Button color="error">
                  <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700" }}
                  >
                    {" "}
                    <CircularProgress />
                  </Typography>
                </Button>
              ) : (
                <Button onClick={handleCancel} color="error">
                  <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "1rem", fontWeight: "700" }}
                  >
                    {" "}
                    Huỷ duyệt
                  </Typography>
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </Box>
        <Box mt={2} display={"flex"} gap={3} className="col-3">
          <SearchComponent
            value={stateName}
            setValue={setStateName}
            placeholder={"Tìm kiếm tên sản phẩm...."}
          ></SearchComponent>
          <Button
            variant="contained"
            color="info"
            sx={{ fontWeight: 700, color: "white" }}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </Box>
        {/* Edit */}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseModal handleclose={handleCloseEdit}></CloseModal>
            <Typography mb={3} color={"black"} variant="h4" fontWeight={600}>
              Điều chỉnh sản phẩm
            </Typography>
            <form onSubmit={handleSubmitEdit}>
              <Box className="row">
                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount">Giá gốc</Label>
                    <input
                      type="text"
                      name="price_for_customer"
                      disabled="true"
                      value={parseInt(
                        stateProductEdit?.price_original
                      ).toLocaleString("en-US")}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                    />
                  </Box>
                </Box>

                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount">Giá bán lẻ</Label>
                    <input
                      type="text"
                      name="price_retail"
                      value={stateProductEdit?.price_retail}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                    />
                    {errorsEdit.price_retail && (
                      <Typography color={"red"}>
                        * {errorsEdit.price_retail}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className="row">
                <Box className="col-xs-12 col-md-12">
                  <Box mt={2}>
                    <Label htmlFor="discount">Ghi chú từ PDP</Label>
                    <input
                      type="text"
                      disabled="true"
                      value={stateProductEdit?.note}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-xs-12 col-md-12">
                  <Box mt={2}>
                    <Label htmlFor="discount">Điểm (POINT)</Label>
                    <input
                      type="number"
                      name="point"
                      disabled="true"
                      value={stateProductEdit?.point}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="row">
                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount">Giá bán</Label>
                    <input
                      type="text"
                      name="price_for_customer"
                      disabled="true"
                      value={parseInt(
                        stateProductEdit?.price_for_customer
                      ).toLocaleString("en-US")}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Mô tả"
                    />
                  </Box>
                </Box>
                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount">Giảm giá</Label>
                    <input
                      type="text"
                      disabled="true"
                      value={stateProductEdit?.sales + `%`}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount_for_admin">
                      Chiết khấu dành cho admin
                    </Label>
                    <input
                      type="number"
                      name="discount_for_admin"
                      value={stateProductEdit?.discount_for_admin}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Chiết khấu cho admin"
                    />
                    {errorsEdit.discount && (
                      <Typography color={"red"}>
                        * {errorsEdit.discount}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="profit_for_admin">
                      Lợi nhuận cho admin
                    </Label>
                    <input
                      type="text"
                      disabled="true"
                      value={parseFloat(
                        stateProductEdit?.profit_for_admin
                      ).toLocaleString("en-US")}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      // placeholder="Lợi nhuận cho admin"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="row">
                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="discount_for_seller">
                      Chiết khấu dành cho seller
                    </Label>
                    <input
                      type="number"
                      name="discount_for_seller"
                      value={stateProductEdit?.discount_for_seller}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Mô tả"
                    />
                    {errorsEdit.discount && (
                      <Typography color={"red"}>
                        * {errorsEdit.discount}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box className="col-xs-12 col-md-6">
                  <Box mt={2}>
                    <Label htmlFor="profit_for_seller">
                      Lợi nhuận cho seller
                    </Label>
                    <input
                      type="text"
                      disabled="true"
                      name="profit_for_seller"
                      value={parseFloat(
                        stateProductEdit.profit_for_seller
                      ).toLocaleString("en-US")}
                      onChange={handleChangeEdit}
                      style={{ height: "48px", fontSize: ".9rem" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Mô tả"
                    />
                  </Box>
                </Box>
              </Box>

              {/* <Box mt={3} width={"200px"}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Label htmlFor="file">Trạng thái sản phẩm</Label>
                    <select
                      onChange={handleChangeEdit}
                      name="status"
                      value={stateProductEdit.status}
                      style={{ height: "40px" }}
                    >
                      <option value={0}>--Chờ duyệt--</option>
                      <option value={1}>--Đã huỷ--</option>
                      <option value={2}>--Đã duyệt--</option>
                    </select>
                    {errorsEdit.category && (
                      <Typography color={"red"}>* {errors.category}</Typography>
                    )}
                  </FormControl>
                </Box>
              </Box> */}

              <Box mt={3} display={"flex"}>
                {Isloading ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress color="warning" />
                    <Typography fontWeight={500} color={"black"}>
                      {" "}
                      Hệ thống đang sử lý ....
                    </Typography>
                  </Box>
                ) : (
                  <Button color="inherit" className="bg-warning" type="submit">
                    Điều chỉnh
                  </Button>
                )}

                <Button
                  sx={{ marginLeft: "2rem" }}
                  className="bg-dark-subtle"
                  onClick={handleCloseEdit}
                >
                  Huỷ
                  {/* <CancelIcon sx={{ color: "gray" }}></CancelIcon> */}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box
          m="40px 0 0 0"
          height="62vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "1.4rem",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
              fontSize: "1.2rem",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            pagination
            page={statePageProvider}
            pageSize={stateLimit}
            rowCount={rowCount}
            paginationMode="server"
            checkboxSelection
            onPageChange={onHandleChangePage}
            onPageSizeChange={onHandleChangePageSize}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            rows={newArray || []}
            columns={columns}
            localeText={{
              toolbarFilters: "Chọn lọc",
              toolbarDensity: "Mật độ",
            }}
            components={{ Toolbar: CustomToolbar }}
            getRowClassName={getRowClassName}
          />
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
