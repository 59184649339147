import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "./scenes/dashboard";
import Order from "./scenes/order";

import Product from "./scenes/product";

import Form from "./scenes/form";

import Login from "./scenes/login";
import AccountJoin from "./scenes/accountJoin/AccountJoin";
import DETAILORDER from "./scenes/detailOrder";
import Agency from "./scenes/agency";
import AccountManage from "./scenes/account";
import Common from "./scenes/common/common";
import Categories from "./scenes/category/categories";
import AccountUpgrade from "./scenes/accountUpgrade";
import Transaction from "./scenes/transaction";
import DETAILUPGRADE from "./scenes/detailUpgrade";
import ProductDetails from "./scenes/productDetail/ProductDetail";
import Customer from "./scenes/customer";
import OrderPdp from "./scenes/order/OrderPdp";
import OrderSeller from "./scenes/order/OrderSeller";
import StatisticPDP from "./scenes/statistic/StatisticPDP";
function App() {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={<Common childrend={<Dashboard></Dashboard>}></Common>}
      />
      <Route
        path="/san-pham"
        element={<Common childrend={<Product />}></Common>}
      />
      <Route
        path="/chi-tiet-san-pham/:productid"
        element={<Common childrend={<ProductDetails />}></Common>}
      ></Route>
      <Route
        path="/don-hang"
        element={<Common childrend={<Order />}></Common>}
      />
      <Route
        path="/quan-ly-tai-khoan"
        element={<Common childrend={<AccountManage />}></Common>}
      />
      <Route
        path="/quan-ly-khach-hang"
        element={<Common childrend={<Customer />}></Common>}
      />
      <Route
        path="/user-upgrade"
        element={<Common childrend={<AccountUpgrade />}></Common>}
      />
      <Route
        path="/transaction"
        element={<Common childrend={<Transaction />}></Common>}
      />
      <Route
        path="/tai-khoan-tham-gia-seller/:accountid"
        element={<Common childrend={<AccountJoin />}></Common>}
      />
      <Route
        path="/chi-tiet-don-hang/:purchase_id"
        element={<Common childrend={<DETAILORDER />}></Common>}
      />
      <Route
        path="/chi-tiet-nang-cap/:upgrade_id"
        element={<Common childrend={<DETAILUPGRADE />}></Common>}
      />

      <Route
        path="/dai-ly"
        element={<Common childrend={<Agency />}></Common>}
      />
      <Route
        path="/thong-tin"
        element={<Common childrend={<Form />}></Common>}
      />
      <Route
        path="/muc-luc"
        element={<Common childrend={<Categories />}></Common>}
      />
      <Route
        path="/loi-nhuan-dai-ly"
        element={<Common childrend={<OrderSeller />}></Common>}
      />
      <Route
        path="/loi-nhuan-pdp"
        element={<Common childrend={<OrderPdp />}></Common>}
      />
      <Route
        path="/thong-ke"
        element={<Common childrend={<StatisticPDP />}></Common>}
      />

      <Route path="/" element={<Login />} />
    </Routes>
  );
}

export default App;
