import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Typography,
  CircularProgress,
  useTheme,
  Breadcrumbs,
  Link,
} from "@mui/material";

import { GET_UPGRADE_REQUEST } from "../../apis/handleUpgradeDetails";
import { useState } from "react";

import { Store } from "react-notifications-component";
import LoginIcon from "@mui/icons-material/Login";

import { notification_component } from "../../components/notifications";
import { CaseDatetimeToYYYYMMDD } from "../../components/function";
import { ACCEPT_UPGRADE_ACCOUNT } from "../../apis/handleAccount";
import { notification_verify } from "../accountUpgrade/components/components";
import { CONFIRM_TRANSACTION } from "../../apis/handleTransaction";
const DETAILUPGRADE = () => {
  const { upgrade_id } = useParams();
  const [stateDetails, setStateDetails] = useState([]);
  const [statePurchase, setStatePurchase] = useState([]);
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });

  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return ACCEPT_UPGRADE_ACCOUNT(body);
    },
  });
  const HANDLE_CONFIRM_TRANSACTION = useMutation({
    mutationFn: (body) => {
      return CONFIRM_TRANSACTION(body);
    },
  });
  const DATA_GET_UPGRADE_REQUEST = useQuery({
    queryKey: ["GET_PURCHASE_DETAILS"],
    queryFn: async () => {
      const rs = await GET_UPGRADE_REQUEST(upgrade_id);
      if (rs?.data?.data) {
        setStateDetails(rs?.data?.data[0]);
      }

      return rs;
    },
  });
  const handleAccess = async () => {
    try {
      setIsloading({
        ...Isloading,
        loading_verify: true,
      });

      VERIFY_ACCOUNT_USER.mutate(
        {
          upgrade_id: upgrade_id,
        },
        {
          onSuccess: (data) => {
            Store.addNotification(notification_verify);
            DATA_GET_UPGRADE_REQUEST.refetch();
            setIsloading({
              ...Isloading,
              loading_verify: false,
            });
            HANDLE_CONFIRM_TRANSACTION.mutate(
              {
                code: stateDetails?.code_upgrade,
              },
              {
                onSuccess: (data) => {
                  console.log("data " + data);
                },
                onError: (err) => {
                  console.log("err transaction" + err);
                },
              }
            );
          },
        }
      );
    } catch (error) {
      setIsloading({
        ...Isloading,
        loading_verify: false,
      });
      console.error("Error creating users:", error);
    }
  };

  return (
    <>
      {" "}
      <Box p={3} className="header-details">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/#/transaction">
            Quản lý giao dịch
          </Link>

          <Typography color="text.primary">Chi tiết nâng cấp</Typography>
        </Breadcrumbs>
      </Box>
      <Box p={3}>
        <ButtonGroup variant="contained" aria-label="Basic button group">
          {Isloading.loading_verify ? (
            <Button color="success">
              <LoginIcon sx={{ color: "white" }}></LoginIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                <CircularProgress />
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={handleAccess}
              disabled={
                parseInt(stateDetails?.status) ===
                  parseInt(process.env.REACT_APP_STATUS_UPGRADE_ACCEPT) ||
                !stateDetails?.is_sent_payment
              }
              color="success"
            >
              <LoginIcon sx={{ color: "white" }}></LoginIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                Xác nhận
              </Typography>
            </Button>
          )}
        </ButtonGroup>
        <Typography mt={1}>Đơn yêu cầu này đã được thanh toán</Typography>
      </Box>
      {DATA_GET_UPGRADE_REQUEST.isFetching ? (
        <Typography fontSize={"1.5rem"} p={5}>
          Đang tải dữ liệu...
        </Typography>
      ) : (
        <>
          {" "}
          {stateDetails?.code_upgrade ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                padding: "100px",
                justifyContent: "space-between",
              }}
            >
              <Box
                className="col-sx-12 col-md-8"
                sx={{
                  backgroundColor: "#1f2a40",
                  height: "auto",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  gap: "10px",

                  border: "solid 3px",
                  padding: "16px",
                  fontSize: "1.2rem",
                }}
              >
                <Divider
                  sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                  textAlign="left"
                >
                  Thông tin nâng cấp
                </Divider>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Mã giao dịch :
                  </Typography>
                  {stateDetails?.code_upgrade}
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Trạng thái :
                  </Typography>

                  {parseInt(stateDetails?.status) ===
                  parseInt(process.env.REACT_APP_STATUS_UPGRADE_PENDING) ? (
                    <Typography fontSize={"1.2rem"} color={"orangered"}>
                      ĐANG CHỜ XÁC NHẬN
                    </Typography>
                  ) : parseInt(stateDetails?.status) ===
                    parseInt(process.env.REACT_APP_STATUS_UPGRADE_ACCEPT) ? (
                    <Typography fontSize={"1.2rem"} color={"skyblue"}>
                      ĐÃ XÁC NHẬN
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Thời điểm tạo {": "}
                  </Typography>
                  {CaseDatetimeToYYYYMMDD(stateDetails?.created_at)}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Tình trạng dùng {": "}
                  </Typography>
                  {stateDetails?.in_use ? "Đang sử dụng" : "Không sử dụng"}
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Hoá đơn {": "}
                  </Typography>
                  {stateDetails?.is_sent_payment
                    ? "Đã thanh toán"
                    : "Chưa thanh toán"}
                </Box>
                <Divider
                  sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                  textAlign="left"
                >
                  Thông tin tài khoản
                </Divider>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - ID {": "}
                  </Typography>
                  {stateDetails?.seller_info?.phone}
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Tên {": "}
                  </Typography>
                  {stateDetails?.seller_info?.name}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - email {": "}
                  </Typography>
                  {stateDetails?.seller_info?.email}
                </Box>

                <Divider
                  sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                  textAlign="left"
                >
                  Thông tin gói nâng cấp
                </Divider>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - ID {": "}
                  </Typography>
                  {stateDetails?.package_info?._id}
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Tên gói {": "}
                  </Typography>
                  {stateDetails?.package_info?.name}
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Thời hạn {": "}
                  </Typography>
                  {stateDetails?.package_info?.duration_in_months}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                    - Giá tiền {": "}
                  </Typography>
                  {parseInt(stateDetails?.package_info?.price).toLocaleString(
                    "en-us"
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography fontSize={"1.5rem"} p={5}>
              Đơn yêu cầu này không tìm thấy !
            </Typography>
          )}
        </>
      )}
    </>
  );
};
export default DETAILUPGRADE;
