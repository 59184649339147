import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import { VERIFY_ACCOUNT } from "../../apis/handleAccount";
import { GET_ALL_TRANSACTION_ACCOUNT_UPGRADE } from "../../apis/handleTransaction";
import { GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import {
  columns,
  notification_verify,
  notification_unverify,
  notification_ban,
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
} from "./components/components";
import { Store } from "react-notifications-component";
import { CustomToolbar } from "../../components/function";
import { SearchComponent } from "../../components/components";
const AccountManage = ({ data }) => {
  const [stateAccount, setStateAccount] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);

  const [stateSort, setStateSort] = useState("asc");
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateCodeTransactionSearch, setStateCodeTransactionSearch] =
    useState("");
  const [stateStatus, setStateStatus] = useState("");

  const [selectedRow, setSelectedRow] = useState([]);
  const [stateRole, setStateRole] = useState("");
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(20);
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DATA_GET_ALL_ACCOUNT = useQuery({
    queryKey: [
      "GET_ALL_TRANSACTION_ACCOUNT_UPGRADE",
      statePage,
      stateStatus,
      stateSortDate,
      stateCodeTransactionSearch,
    ],
    queryFn: async () => {
      const res = await GET_ALL_TRANSACTION_ACCOUNT_UPGRADE({
        page: statePage + 1,
        limit: stateLimit,
        sort: stateSort,
        status: stateStatus,
        transaction_code: stateCodeTransactionSearch,
        date: stateSortDate,
      });
      if (res?.data?.data) {
        let newArray = res?.data?.data?.transactions.map((item, index) => {
          return {
            ...item,
            id: item._id,
          };
        });
        setStateListUpgrade(newArray);
        setRowCount(parseInt(res?.data?.data?.total_transactions));
      }

      return res;
    },
  });

  // const DATA_ALL_USER = useQuery({
  //   queryKey: ["GET_ALL_USER_ACCOUNT", stateRole],
  //   queryFn: async () => {
  //     const result = await GET_ALL_ACCOUNT_BY_ROLE(stateRole);
  //     if (result?.data?.data) {
  //       setStateAccount(result?.data?.data);
  //     }
  //     return result;
  //   },
  // });
  const handleChangeGetProvider = (e, newValue) => {
    setStateRole(newValue.role);
  };

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return VERIFY_ACCOUNT(body);
    },
  });

  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_verify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_verify);

                  setIsloading({
                    ...Isloading,
                    loading_verify: false,
                  });
                  setSelectedRow([]);
                  setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_verify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  const handleBANNED = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_ban: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_BANNED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_ban);

                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_ban: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_ban: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleUNVERIFY = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_unverify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_unverify);

                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_unverify: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_unverify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  return (
    <Box m="20px">
      <Header
        title="Quản lý giao dịch"
        subtitle={
          "Mọi giao dịch sẽ được thể hiện lên bảng bao gồm: nâng cấp và đơn hàng"
        }
      />
      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateCodeTransactionSearch}
          setValue={setStateCodeTransactionSearch}
          placeholder={"Tìm kiếm Mã giao dịch...."}
        ></SearchComponent>
      </Box>
      <Stack
        direction="row"
        width={"50%"}
        // divider={<Divider orientation="vertical" flexItem />}
        justifyContent={"space-between"}
      >
        <Box
          p={2}
          flex={1}
          textAlign={"center"}
          borderRight={"solid 1px #f7f7f7"}
          paddingLeft={"0px"}
        >
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Chọn lọc
          </Typography>
          <SelectOldNewOrder
            setStatePage={setStatePage}
            setStateSortDate={setStateSortDate}
            stateSortDate={stateSortDate}
          ></SelectOldNewOrder>
        </Box>
        <Box p={2} flex={1} textAlign={"center"}>
          {" "}
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Tình Trạng
          </Typography>
          <Typography fontWeight={600}>
            <UnstyledSelectIntroduction
              stateStatus={stateStatus}
              setStatePage={setStatePage}
              setStateStatus={setStateStatus}
              // handleRefreshData={handleRefreshData}
            ></UnstyledSelectIntroduction>
          </Typography>
        </Box>
      </Stack>
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={stateListUpgrade || []}
          columns={columns}
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default AccountManage;
