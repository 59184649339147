import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import "./style.css";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { HandleLogin } from "../../apis/handleAccount";
import { useMutation } from "@tanstack/react-query";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Desktopscreen = () => {
  let nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [FormLogin, setFormLogin] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [DataError, setDataError] = useState({});
  const MutationLogin = useMutation({
    mutationFn: (body) => {
      return HandleLogin(body);
    },
  });
  const onChange = (e) => {
    setFormLogin({
      ...FormLogin,
      [e.target.name]: e.target.value,
    });
  };

  const sLogin = () => {
    setIsloading(true);
    MutationLogin.mutate(FormLogin, {
      onSuccess: (data) => {
        if (data?.errors) {
          setDataError(data);
          setIsloading(false);
        } else {
          localStorage.setItem("token", data?.data?.data?.access_token);
          const decodedToken = jwtDecode(data?.data?.data?.access_token);
          if (
            decodedToken?.roles != parseInt(process.env.REACT_APP_ROLE_ADMIN)
          ) {
            setDataError({
              errors: {
                email: {
                  msg: "Tài khoản không hợp lệ",
                },
              },
            });
            setIsloading(false);
          } else {
            nav("/dashboard");
          }
        }
      },
      onError: (err) => {
        setIsloading(false);
        setDataError({
          errors: {
            email: {
              msg: "Lỗi đăng nhập vui lòng thử lại!",
            },
          },
        });
      },
    });
  };

  return (
    <>
      {" "}
      <Grid className="row" height={"100%"}>
        <Grid p={0} className="col-xs-12 col-md-8">
          <Box display={"flex"} className="bgleft" textAlign={"center"}></Box>
        </Grid>
        <Grid
          className="col-xs-12 col-md-4"
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
        >
          <Box pl={3} pr={3} display={"flex"} textAlign={"center"}>
            <form>
              <Typography
                fontSize={"1.4993rem"}
                fontWeight={600}
                variant="subtitle1"
              >
                Chào mừng đến với My Biz Portal! 👋🏻
              </Typography>
              <Typography
                fontSize={"1.4993rem"}
                variant="subtitle1"
              ></Typography>
              <Typography variant="subtitle2" fontWeight={"200"} color={"gray"}>
                Vui lòng đăng nhập để theo dõi và quản lý hoạt động kinh doanh
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& .MuiTextField-root": { width: "100%" },
                }}
              >
                <TextField
                  label={"Tên đăng nhập"}
                  id="margin-normal"
                  sx={{ width: "100%" }}
                  margin="normal"
                  type="email"
                  name="email"
                  value={FormLogin.email}
                  onChange={onChange}
                />
                <TextField
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={FormLogin.password}
                  onChange={onChange}
                  label={"Mật khẩu"}
                  id="margin-normal"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={3}>
                {" "}
                {isLoading ? (
                  <button
                    className="btn btn-success"
                    type="submit"
                    style={{ width: "100%" }}
                    disabled={isLoading}
                  >
                    {" "}
                    <CircularProgress
                      sx={{ fontSize: "10px" }}
                    ></CircularProgress>
                  </button>
                ) : (
                  <button
                    onClick={sLogin}
                    className="btn btn-success"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    ĐĂNG NHẬP
                  </button>
                )}
              </Box>

              <Box
                mt={3}
                display={"flex"}
                alignItems={"center"}
                className="form-group"
              >
                <Typography
                  margin={"auto"}
                  fontStyle={"italic"}
                  color={"blue"}
                  fontSize={".8rem"}
                >
                  <a href="#"> Đăng ký nhà cung cấp Fclass Agency</a>
                </Typography>
              </Box>
              <Box mt={2}>
                {DataError && DataError.errors?.email?.msg ? (
                  <Alert style={{ marginTop: "1rem" }} severity="error">
                    {DataError.errors?.email?.msg}
                  </Alert>
                ) : (
                  ""
                )}
                {DataError && DataError.errors?.password?.msg ? (
                  <Alert style={{ marginTop: "1rem" }} severity="error">
                    {DataError.errors?.password?.msg}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Desktopscreen;
