import { Box, Typography, FormControl } from "@mui/material";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../../components/function";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import styled from "styled-components";
const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_NOTLINK)}
        >
          Chưa liên kết
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_LINK)}
        >
          Đã tham gia MALL
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_ACTIVE)}
        >
          Đã liên kết
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const columns = [
  {
    field: "phone",
    headerName: "Mã khách hàng",
    width: 200,
  },

  {
    field: "seller_info",
    headerName: "Thông tin seller",
    width: 200,
    renderCell: (params) => (
      <div>
        {params?.value?.phone ? params?.value?.phone : params?.value?.email}
      </div>
    ),
  },
  {
    field: "name",
    headerName: "Tên khách hàng",
    width: 200,
  },

  {
    field: "status",
    headerName: "Trạng thái",
    width: 200,
    renderCell: (params) => (
      <>
        {params?.value ===
        parseInt(process.env.REACT_APP_STATUS_CUSTOMER_NOTLINK) ? (
          <Typography color={"#607CFB"}>Chưa liên kết</Typography>
        ) : params?.value ===
          parseInt(process.env.REACT_APP_STATUS_CUSTOMER_LINK) ? (
          <Typography color={"orangered"}>Đã tham gia MALL</Typography>
        ) : params?.value ===
          parseInt(process.env.REACT_APP_STATUS_CUSTOMER_ACTIVE) ? (
          <Typography color={"blueviolet"}>Đã liên kết</Typography>
        ) : (
          ""
        )}
      </>
    ),
  },

  {
    field: "address",
    headerName: "Địa chỉ",
    width: 300,
  },

  {
    field: "created_at",
    headerName: "Ngày khởi tạo",
    width: 200,
    renderCell: (params) => (
      <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
    ),
  },
];
export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
