import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Avatar, Box, FormControl, Typography } from "@mui/material";
import { CaseDatetimeToYYYYMMDD } from "../../../components/function";
import "../style.css";
import styled from "styled-components";
const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả sản phẩm</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION)}
        >
          Đang chờ xác nhận
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_WAIT_FOR_GETTING)}>
          Đang được lấy hàng
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_IN_PROGRESS)}>
          Đang được vận chuyển
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_DELIVERED)}>
          Đã giao
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_CANCELLED)}>
          Đã huỷ
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};
export const columns = ({ handleOpen, setStateCodeDH }) => [
  {
    field: "code_purchase",
    width: 150,
    headerName: "Mã đơn hàng",
    renderCell: (params) => (
      <>
        {params?.row?.payment_method?._id ===
        process.env.REACT_APP_PAYMENT_METHOD_CK ? (
          <Typography> {params?.value}</Typography>
        ) : (
          <a
            style={{ fontWeight: "700" }}
            href={`/#/chi-tiet-don-hang/${params.row.id}`}
          >
            {params?.value}
          </a>
        )}
      </>
    ),
  },

  {
    field: "seller_info",
    headerName: "Người lên đơn",
    width: 200,

    renderCell: (params) => <span>{params?.value.name}</span>,
  },

  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 150,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
  {
    field: "purchase_total_price",
    headerName: "Tổng giá trị đơn hàng",
    width: 150,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "purchase_total_profit_for_seller",
    headerName: "Tổng Lợi nhuận cho người bán",
    width: 150,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "purchase_total_profit_for_admin",
    headerName: "Tổng Lợi nhuận cho admin",
    width: 150,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },

  {
    field: "payment_method",
    headerName: "Phương thức thanh toán",
    width: 350,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // Thanh toán nhận hàng
          params?.value?._id === process.env.REACT_APP_PAYMENT_METHOD_CK
            ? "green"
            : "#6ff7ff"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "cost_bearer",
    headerName: "Người chịu phí vận chuyển",
    width: 250,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // người bán
          params?.value?._id === process.env.REACT_APP_COST_BEARER_NB
            ? "#FF5733"
            : "#00ff51"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "purchase_items",
    headerName: "Số lượng sản phẩm",
    width: 200,
    renderCell: (params) => (
      // <button
      //   onClick={() => handleOpen(params.value)}
      //   style={{
      //     padding: "10px",
      //     borderRadius: "10px",
      //     backgroundColor: "white",

      //     color: "black",
      //   }}
      //   className="btn"
      // >
      //   <TouchAppIcon></TouchAppIcon>
      //   {parseInt(params?.value?.length).toLocaleString("en-US")} Sản phẩm
      // </button>

      <button
        onClick={() => {
          setStateCodeDH(params?.row?.code_purchase);
          handleOpen(params?.value);
        }}
        style={{ padding: "10px", borderRadius: "10px", fontWeight: 600 }}
      >
        <TouchAppIcon></TouchAppIcon>
        {params.value.length} Sản phẩm
      </button>
    ),
  },
  {
    field: "status",
    headerName: "Trạng thái",
    width: 300,
    renderCell: (params) => (
      <>
        {params.value ===
        parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION) ? (
          <Typography variant="h4" p={2} bgcolor={"#9b9b61"}>
            ĐANG CHỜ XÁC NHẬN
          </Typography>
        ) : params.value ===
          parseInt(process.env.REACT_APP_WAIT_FOR_GETTING) ? (
          <Typography variant="h4" p={2} bgcolor={"#00BFFF"}>
            ĐANG ĐƯỢC LẤY HÀNG
          </Typography>
        ) : params.value === parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
          <Typography variant="h4" p={2} bgcolor={"#FFA500"}>
            ĐƠN ĐANG VẬN CHUYỂN
          </Typography>
        ) : params.value === parseInt(process.env.REACT_APP_DELIVERED) ? (
          <Typography variant="h4" p={2} bgcolor={"#008000"}>
            ĐÃ ĐƯỢC GIAO
          </Typography>
        ) : (
          <Typography variant="h4" p={2} bgcolor={"#FF0000"}>
            ĐÃ HUỶ
          </Typography>
        )}
      </>
    ),
    cellClassName: "column-sticky",
  },

  {
    field: "purchase_total_point",
    headerName: "Tổng số điểm (POINT)",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "customer",
    headerName: "Thông tin khách hàng",
    width: 1000,
    renderCell: (params) => (
      <>
        <span>Tên khách hàng : {params?.value?.name} - </span>
        <br></br>
        <span> Số điện thoại : {params?.value?.phone} - </span>
        <br></br>
        <span> Địa chỉ : {params?.value?.address}</span>
      </>
    ),
  },
];

export const columnsPDP = (handleOpen) => [
  {
    field: "name",
    flex: 1,
    headerName: "Tên đại lý",
    renderCell: (params) => <span>{params?.value}</span>,
  },
  {
    field: "avatar",
    headerName: "Ảnh đại diện",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <Avatar src={params?.value} sx={{ width: 70, height: 70 }}></Avatar>
        ) : (
          "Chưa có ảnh"
        )}
      </div>
    ),
  },
  {
    field: "phone",
    flex: 1,
    headerName: "Số điện thoại",
    renderCell: (params) => <span>{params?.value}</span>,
  },
  {
    field: "affiliateRevenues",
    flex: 1,
    headerName: "Doanh số",
    renderCell: (params) => (
      <>
        <Typography mr={2} fontSize={"1.2rem"}>
          Hoa hồng bán lẻ:{" "}
          {parseInt(params?.value?.totalAffiliateMoney).toLocaleString("en-us")}
        </Typography>
        {" - "}
        <Typography ml={2} fontSize={"1.2rem"}>
          Tổng số đơn:{" "}
          {parseInt(params?.value?.totalOrders).toLocaleString("en-us")}
        </Typography>
      </>
    ),
  },
];

export const columnsSELLER = (handleOpen) => [
  {
    field: "name",
    flex: 1,
    headerName: "Tên seller",
    renderCell: (params) => <span>{params?.value}</span>,
  },
  {
    field: "avatar",
    headerName: "Ảnh đại diện",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <Avatar src={params?.value} sx={{ width: 70, height: 70 }}></Avatar>
        ) : (
          "Chưa có ảnh"
        )}
      </div>
    ),
  },
  {
    field: "phone",
    flex: 1,
    headerName: "Số điện thoại",
    renderCell: (params) => <span>{params?.value}</span>,
  },
  {
    field: "affiliateRevenues",
    flex: 1,
    headerName: "Doanh số",
    renderCell: (params) => (
      <>
        <Typography mr={2} fontSize={"1.2rem"}>
          Hoa hồng bán lẻ:{" "}
          {parseInt(params?.value?.totalAffiliateMoney).toLocaleString("en-us")}
        </Typography>
        {" - "}
        <Typography ml={2} fontSize={"1.2rem"}>
          Tổng số đơn:{" "}
          {parseInt(params?.value?.totalOrders).toLocaleString("en-us")}
        </Typography>
      </>
    ),
  },
];

export const columnsInviteSeller = [
  {
    field: "seller_info",
    width: 350,
    headerName: "Thông tin tài khoản",
    renderCell: (params) => (
      <span style={{ color: "blanchedalmond" }}>
        Tên: {params?.value?.name} , sđt: {params?.value?.phone}
      </span>
    ),
  },
  {
    field: "package_info",
    headerName: "Thông tin gói",
    width: 350,
    renderCell: (params) => {
      return (
        <>
          <span style={{ color: "lightblue" }}>
            Tên gói: {params?.value?.name} , thời hạn:{" "}
            {params?.value?.duration_in_months}
          </span>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
];
