import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "../../theme";
import DatePicker from "react-date-picker";
import SearchIcon from "@mui/icons-material/Search";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import Person4Icon from "@mui/icons-material/Person4";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { columnsInviteSeller, columnsPDP } from "./component/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { style } from "./component/components";
import { GET_ALL_ACCOUNT_SELLER } from "../../apis/handleAccount";
import { CustomToolbar } from "../../components/function";
import {
  Get_Purchase_By_SELLER,
  Get_Statistic_Invite_By_SELLER,
} from "../../apis/handlePurchase";
const OrderSeller = () => {
  const today = new Date();
  // Ngày bắt đầu của tháng
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Ngày kết thúc của tháng
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDateF = `${year}-${month}-${String(
    startOfMonth.getDate()
  ).padStart(2, "0")}`;
  const formattedDateT = `${year}-${month}-${String(
    endOfMonth.getDate()
  ).padStart(2, "0")}`;
  const [dateF, onChangeF] = useState(formattedDateF);
  const [dateT, onChangeT] = useState(formattedDateT);

  const [statePDP, setStatePDP] = useState("");
  const [stateSearchPurchaseSELLER, setStateSearchPurchaseSELLER] = useState(
    {}
  );
  const [stateSearchInviteSELLER, setStateSearchInviteSELLER] = useState({});
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [stateAccount, setStateAccount] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const DATA_ALL_USER = useQuery({
    queryKey: ["GET_ALL_SELLER_ACCOUNT"],

    queryFn: async () => {
      const result = await GET_ALL_ACCOUNT_SELLER();
      if (result?.data?.data) {
        let newArrayObject = result?.data?.data.map((obj) => {
          return { ...obj, label: obj?.name + " - " + obj?.phone };
        });
        setStateAccount(newArrayObject);
        setFilteredAccounts(newArrayObject.slice(0, 10));
      }
      return result;
    },
    enabled: false,
  });

  const handleInputChange = (event, value) => {
    if (value) {
      if (stateAccount) {
        // Lọc dữ liệu dựa trên giá trị nhập vào
        const filtered = stateAccount.filter((account) =>
          account.label.toLowerCase().includes(value.toLowerCase())
        );
        // Cập nhật danh sách hiển thị
        setFilteredAccounts(filtered);
      }
    } else {
      DATA_ALL_USER.refetch();
    }
  };

  const HANDLE_GET_DATA_PURCHASE_SELLER = useMutation({
    mutationKey: ["DATA_PURCHASE"],
    mutationFn: (body) => {
      return Get_Purchase_By_SELLER(body);
    },
  });
  const HANDLE_GET_DATA_INVITE_SELLER = useMutation({
    mutationKey: ["DATA_INVITE"],
    mutationFn: (body) => {
      return Get_Statistic_Invite_By_SELLER(body);
    },
  });
  const handleChangeGetProvider = (e, newValue) => {
    setStatePDP(newValue._id);
  };
  const handleClose = () => setOpen(false);
  const handleOpen = (data) => {
    setModalData(data);
    setOpen(true);
  };
  const handleSearchSeller = () => {
    if (statePDP) {
      let FormGet = {
        user_id: statePDP,
        startTime: dateF,
        endTime: dateT,
      };
      HANDLE_GET_DATA_PURCHASE_SELLER.mutate(FormGet, {
        onSuccess: (data) => {
          setStateSearchPurchaseSELLER(data?.data);
        },
        onError: (err) => {
          console.log("err " + err);
        },
      });
      HANDLE_GET_DATA_INVITE_SELLER.mutate(FormGet, {
        onSuccess: (data) => {
          setStateSearchInviteSELLER(data?.data);
          console.log("CHECK ", JSON.stringify(data?.data));
        },
        onError: (err) => {
          console.log("err " + err);
        },
      });
    } else {
      alert("Vui lòng chọn SELLER");
    }
  };
  const columnsShowPDP = columnsPDP(handleOpen);
  useEffect(() => {
    DATA_ALL_USER.refetch();
  }, []);
  return (
    <Box
      m="20px"
      height={"90vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "auto",
      }}
    >
      <Divider>
        <Chip label="Thống kê lợi nhuận đơn hàng của SELLER" size="medium" />
      </Divider>

      {/* //PDP */}
      <Box>
        <Typography mb={2} variant="h5">
          Bảng điều khiển
        </Typography>
        <Autocomplete
          options={filteredAccounts}
          onInputChange={handleInputChange}
          onChange={handleChangeGetProvider}
          sx={{ width: 300 }}
          renderInput={(params, index) => (
            <TextField {...params} label="CHỌN SELLER" />
          )}
        />
      </Box>

      <Box
        mt={2}
        display={"flex"}
        width={"600px"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontSize={"1.1rem"}>Ngày bắt đầu (YYYY-MM-DD)</Typography>
          <DatePicker
            clearIcon={null}
            format="y-MM-dd"
            onChange={(e) => {
              let year = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[0];
              let Month = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[1];
              let day = parseInt(
                parseInt(
                  JSON.stringify(e).replace('"', "").split("T")[0].split("-")[2]
                )
              )
                .toString()
                .padStart(2, "0");

              let Formdate = year + "-" + Month + "-" + day;
              onChangeF(Formdate);
            }}
            value={dateF || today}
          />
        </Box>
        <Box>
          <Typography fontSize={"1.1rem"}>
            Ngày Kết thúc (YYYY-MM-DD)
          </Typography>
          <DatePicker
            clearIcon={null}
            format="y-MM-dd"
            onChange={(e) => {
              let year = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[0];
              let Month = JSON.stringify(e)
                .replace('"', "")
                .split("T")[0]
                .split("-")[1];
              let day = parseInt(
                parseInt(
                  JSON.stringify(e).replace('"', "").split("T")[0].split("-")[2]
                )
              )
                .toString()
                .padStart(2, "0");

              let Formdate = year + "-" + Month + "-" + day;
              onChangeT(Formdate);
            }}
            value={dateT || today}
          />
        </Box>

        <Box>
          <button onClick={handleSearchSeller} className="btn btn-info">
            <SearchIcon sx={{ color: "white" }} fontSize="medium"></SearchIcon>
          </button>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography alignItems={"center"} display={"flex"}>
          <Typography fontSize={"1.1rem"}> Doanh thu trong khoảng:</Typography>

          <Typography ml={1} fontSize={"1rem"} color={"yellowgreen"}>
            {" "}
            {dateF ? dateF : ""}
            <ArrowRightAltIcon sx={{ color: "white" }}></ArrowRightAltIcon>{" "}
            {dateT ? dateT : ""}
          </Typography>
        </Typography>
        <Box alignItems={"center"} display={"flex"}>
          <Typography alignItems={"center"} display={"flex"}>
            <LocalAtmIcon fontSize="large" color="success"></LocalAtmIcon>
            <Typography ml={1} fontSize={"1.1rem"}>
              Tổng doanh thu từ đơn hàng :
            </Typography>
          </Typography>
          <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
            {stateSearchPurchaseSELLER?.totalMoney
              ? parseInt(stateSearchPurchaseSELLER?.totalMoney).toLocaleString(
                  "en-us"
                )
              : 0}
          </Typography>
        </Box>

        <Box alignItems={"center"} display={"flex"}>
          <Typography alignItems={"center"} display={"flex"}>
            <Inventory2Icon fontSize="large" color="success"></Inventory2Icon>
            <Typography ml={1} fontSize={"1.1rem"}>
              Tổng đơn hàng :
            </Typography>
          </Typography>
          <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
            {stateSearchPurchaseSELLER?.purchases
              ? parseInt(stateSearchPurchaseSELLER?.purchases.length)
              : 0}
          </Typography>
        </Box>
        <Box>
          <Box
            m="40px 0 0 0"
            height="55vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontSize: "1.1rem",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                fontSize: "1rem",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              pagination
              rows={
                stateSearchPurchaseSELLER?.purchases
                  ? stateSearchPurchaseSELLER?.purchases.map((item, index) => {
                      return {
                        ...item,
                        id: index,
                      };
                    })
                  : []
              }
              columns={columnsShowPDP}
              localeText={{
                toolbarFilters: "Chọn lọc",
                toolbarDensity: "Mật độ",
              }}
              components={{ Toolbar: CustomToolbar }}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  color={"black"}
                  fontWeight={"600"}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box
                    mr={2}
                    sx={{ bgcolor: "#008000", width: "20px", height: "10px" }}
                  ></Box>
                  Sản phẩm có trong đơn hàng
                </Typography>
                <hr></hr>

                <div class="table-responsive">
                  <table class="table table-primary">
                    <thead>
                      <tr>
                        <th scope="col">Mã sản phẩm</th>
                        <th scope="col">Tên sản phẩm</th>
                        <th scope="col">Hình ảnh</th>
                        <th scope="col">Số lượng bán</th>

                        <th scope="col">Tổng lợi nhuận cho seller</th>
                        <th scope="col">Tổng lợi nhuận cho sàn</th>
                        <th scope="col">Tổng lợi nhuận cho nhà cung cấp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalData &&
                        modalData.map((item, index) => (
                          <tr>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {item?.code}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {item?.product_name}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              <img
                                width={"100"}
                                height={"100"}
                                src={item?.product_images[0]?.path}
                              ></img>
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {item?.buy_count}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {parseInt(
                                item?.total_profit_for_seller
                              ).toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {parseInt(
                                item?.total_profit_for_admin
                              ).toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  item?.provider_id === statePDP
                                    ? "#008000"
                                    : "",
                              }}
                            >
                              {parseInt(
                                item?.total_profit_for_pdp
                              ).toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Box>
            </Modal>
          </Box>
          <hr></hr>
          <Box mt={3} alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <LocalAtmIcon fontSize="large" color="success"></LocalAtmIcon>
              <Typography ml={1} fontSize={"1.1rem"}>
                Tổng doanh thu từ lợi nhuận từ nâng cấp :
              </Typography>
            </Typography>
            <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateSearchInviteSELLER?.totalMoney
                ? parseInt(stateSearchInviteSELLER?.totalMoney).toLocaleString(
                    "en-us"
                  )
                : 0}
            </Typography>
          </Box>

          <Box alignItems={"center"} display={"flex"}>
            <Typography alignItems={"center"} display={"flex"}>
              <Person4Icon fontSize="large" color="success"></Person4Icon>
              <Typography ml={1} fontSize={"1.1rem"}>
                Số lượng tài khoản :
              </Typography>
            </Typography>
            <Typography ml={1} sx={{ color: "#66bb6a" }} fontSize={"1rem"}>
              {stateSearchInviteSELLER?.upgrade
                ? parseInt(stateSearchInviteSELLER?.upgrade.length)
                : 0}
            </Typography>
          </Box>
          <Box
            m="40px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontSize: "1.4rem",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                fontSize: "1.2rem",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              pagination
              paginationMode="server"
              rows={
                stateSearchInviteSELLER?.upgrade
                  ? stateSearchInviteSELLER?.upgrade.map((item, index) => {
                      return {
                        ...item,
                        id: index,
                      };
                    })
                  : []
              }
              columns={columnsInviteSeller}
              localeText={{
                toolbarFilters: "Chọn lọc",
                toolbarDensity: "Mật độ",
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default OrderSeller;
