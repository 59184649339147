import { Typography } from "@mui/material";
import styled from "styled-components";

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
`;
export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

export const Columns = [
  { field: "code", headerName: "Mã sản phẩm", width: 100 },
  {
    field: "name",
    headerName: "Tên sản phẩm",
    width: 200,
    renderCell: (params) => (
      <a href={`/#/chi-tiet-san-pham/${params.row.id}`}>{params.value}</a>
    ),
  },

  {
    field: "images",
    headerName: "Hình ảnh",
    width: 200,
    renderCell: (params) => (
      <div>
        {params?.value[0]?.path ? (
          <img
            src={params.value[0].path}
            alt="Hình ảnh"
            style={{ width: "100px", height: "auto", objectFit: "cover" }}
          />
        ) : (
          "No Images"
        )}
      </div>
    ),
  },

  {
    field: "provider",
    headerName: "Tên nhà cung cấp",
    headerAlign: "left",
    align: "left",
    width: 200,
    renderCell: (params) => (
      <span>{params?.value?.name ? params?.value?.name : ""}</span>
    ),
  },
  {
    field: "destroy",
    headerName: "Tình trạng",
    width: 200,
    cellClassName: "column-sticky",
    renderCell: (params) => (
      <>
        {params.value ===
        parseInt(process.env.REACT_APP_PRODUCT_DETROY_ACTIVE) ? (
          <Typography variant="h5" p={1} bgcolor={"blue"}>
            Đang chờ duyệt
          </Typography>
        ) : params.value ===
          parseInt(process.env.REACT_APP_PRODUCT_DETROY_PROVIDER) ? (
          <Typography variant="h5" p={1} bgcolor={"red"}>
            Đã huỷ từ PDP
          </Typography>
        ) : (
          <Typography variant="h5" p={1} bgcolor={"orangered"}>
            Đã xoá từ ADMIN
          </Typography>
        )}
      </>
    ),
  },
  {
    field: "status",
    headerName: "Trạng thái",
    width: 200,
    cellClassName: "column-sticky-mr",
    renderCell: (params) => (
      <>
        {params.value ===
          parseInt(process.env.REACT_APP_PRODUCT_STATUS_PENDING) ||
        params.value === "0" ? (
          <Typography variant="h4" p={2} bgcolor={"orange"}>
            Đang chờ duyệt
          </Typography>
        ) : params.value ===
            parseInt(process.env.REACT_APP_PRODUCT_STATUS_CANCEL) ||
          params.value === "1" ? (
          <Typography variant="h4" p={2} bgcolor={"red"}>
            Đã huỷ
          </Typography>
        ) : (
          <Typography variant="h4" p={2} bgcolor={"green"}>
            Đã duyệt
          </Typography>
        )}
      </>
    ),
  },
  {
    field: "price_original",
    headerName: "Giá gốc",
    type: "number",
    headerAlign: "left",
    align: "left",
    width: 200,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "price_retail",
    headerName: "Giá bán lẻ",
    type: "number",
    headerAlign: "left",
    align: "left",
    width: 200,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },

  {
    field: "profit",
    headerName: "Lợi nhuận sàn thu về",
    type: "number",
    headerAlign: "left",
    align: "left",
    width: 200,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "discount_for_seller",
    headerName: "Chiết Khấu SELLER",
    width: 300,
    renderCell: (params) => (
      <>
        {params.value} % - {"( "} Lợi nhuận :{" "}
        {parseInt(params.row.profit_for_seller).toLocaleString("en-US")} {" )"}
      </>
    ),
  },

  {
    field: "discount_for_admin",
    headerName: "Chiết Khấu ADMIN",
    width: 300,
    renderCell: (params) => (
      <>
        {params.value} % - {"( "} Lợi nhuận :{" "}
        {parseInt(params.row.profit_for_admin).toLocaleString("en-US")} {" )"}
      </>
    ),
  },

  {
    field: "description",
    headerName: "Mô tả sản phẩm",
    width: 200,
    cellClassName: "name-column--cell",
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "price_for_customer",
    headerName: "Giá bán cho khách hàng",
    type: "number",
    headerAlign: "left",
    align: "left",
    width: 300,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "sold",
    headerName: "Đã bán",
    width: 200,
  },
  {
    field: "discount_for_point",
    headerName: "Chiết Khấu POINT",
    width: 300,
    renderCell: (params) => (
      <>
        {params.value} % - {"( "} POINT :{" "}
        {parseInt(params.row.point).toLocaleString("en-US")} {" )"}
      </>
    ),
  },
  {
    field: "store_company",
    headerName: "Tồn kho (Trụ sở chính)",
    width: 250,
    renderCell: (params) => <span>{parseInt(params.value.stock || 0)}</span>,
  },
];

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80vh",
  overflowY: "auto",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export const notification_Edit_success = ({ title, message }) => {
  return {
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  };
};

export const notification_Error = {
  title: "Thao tác không thành công thành công",
  message: "Vui lòng thử lại !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_status_accept = {
  title: "Thao tác thành công",
  message: "Thành công duyệt sản phẩm !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_status_pending = {
  title: "Thao tác thành công",
  message: "Thành công, chuyển trạng thái sang đang chờ",
  type: "warning",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_status_cancel = {
  title: "Thao tác thành công!",
  message: "Thành công, chuyển trạng thái sang huỷ duyệt!",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
