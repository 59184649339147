export const notification_component = ({ title, message, sta }) => {
  return {
    title: title,
    message: message,
    type: sta,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  };
};
