import api from "./apis";

export const GET_ALL_USER = async (req) => {
  try {
    const res = await api.get(`/Auth/getAll`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};

export const UPDATE_USER_ACCESS = async (req) => {
  try {
    const res = await api.put(`/Auth/update`, {
      email: req.email,
      verify: 1,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};

export const UPDATE_USER_BANNED = async (req) => {
  try {
    const res = await api.put(`/Auth/update`, {
      email: req.email,
      verify: 2,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
export const UPDATE_USER_UNVERIFY = async (req) => {
  try {
    const res = await api.put(`/Auth/update`, {
      email: req.email,
      verify: 0,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
